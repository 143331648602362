import { FormBlock, FormButton, FormInput } from "components";
import DatepickerComponent from "components/datePicker/datePickerComponent";
import InputRow from "components/InputRow";
import LockIcon from "components/LockIcon";
import { CUSTOM_STYLE, RESPONSIVE } from "constant";
import { useCountry } from "context/country";
import { useLand } from "context/land";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";
import Layout from "../../../components/Layout";
import { useLocale } from "../../../context/locale";
// import { getCounty } from "../../../utils/api";
import "./PersonalInformation.css";

const currentYear = new Date().getFullYear();
const fromYear = currentYear - 100;
const years = [];
for (let i = currentYear; i >= fromYear; i -= 1) {
  years.push({ value: i, label: i });
}

const COUNTRY_LIST = {
  de: [
    { value: "AT", label: "Österreich" },
    // { value: "CH", label: "Schweiz" },
    // { value: "LI", label: "Liechtenstein" },
  ],
  en: [
    { value: "AT", label: "Austria" },
    // { value: "CH", label: "Switzerland" },
    // { value: "LI", label: "Liechtenstein" },
  ],
  it: [
    { value: "AT", label: "Austria" },
    // { value: "CH", label: "Svizzera" },
    // { value: "LI", label: "Liechtenstein" },
  ],
  fr: [
    { value: "AT", label: "Autriche" },
    // { value: "CH", label: "Suisse" },
    // { value: "LI", label: "Liechtenstein" },
  ],
};

const EXPATRIATE_LIST = {
  de: [
    {value: 1, expirationDate: true, label: "Aufenthaltsbewilligung"},
    {value: 2, expirationDate: true, label: "Niederlassungsbewilligung"},
    {value: 3, expirationDate: true, label: "Niederlassungsbewilligung - Künstler"},
    {value: 4, expirationDate: true, label: "Niederlassungsbewilligung - Forscher"},
    {value: 5, expirationDate: true, label: "Niederlassungsbewilligung - Sonderfälle unselbständiger Erwerbstätigkeit"},
    {value: 6, expirationDate: true, label: "Niederlassungsbewilligung - ausgenommen Erwerbstätigkeit"},
    {value: 7, expirationDate: true, label: "Niederlassungsbewilligung gemäß § 56 NAG"},
    {value: 8, expirationDate: true, label: "Niederlassungsbewilligung - Angehöriger"},
    {value: 9, expirationDate: true, label: "Familienangehöriger"},
    {value: 10, expirationDate: true, label: "Rot-Weiß-Rot - Karte"},
    {value: 11, expirationDate: true, label: "Rot-Weiß-Rot - Karte plus"},
    {value: 12, expirationDate: true, label: "Blaue Karte EU"},
    {value: 13, expirationDate: false, label: "Daueraufenthalt - EU"},
    {value: 14, expirationDate: true, label: "Subsidiär Schutzberechtigte"},
    {value: 15, expirationDate: true, label: "Asyl (befristet)"},
    {value: 16, expirationDate: false, label: "Asyl (unbefristet)"},
  ],
  en: [
    {value: 1, expirationDate: true, label: "Residence permit"},
    {value: 2, expirationDate: true, label: "Settlement Permit"},
    {value: 3, expirationDate: true, label: "Settlement Permit - Artist"},
    {value: 4, expirationDate: true, label: "Settlement Permit - Researcher"},
    {value: 5, expirationDate: true, label: "Settlement Permit - Special Cases of Paid Employment"},
    {value: 6, expirationDate: true, label: "Settlement Permit - Except Gainful Employment"},
    {value: 7, expirationDate: true, label: "Settlement permit according to § 56 NAG"},
    {value: 8, expirationDate: true, label: "Settlement permit - relative"},
    {value: 9, expirationDate: true, label: "Family member"},
    {value: 10, expirationDate: true, label: "Red-White-Red - Card"},
    {value: 11, expirationDate: true, label: "Red-White-Red - Card plus"},
    {value: 12, expirationDate: true, label: "Blue Card EU"},
    {value: 13, expirationDate: false, label: "Permanent Residence - EU"},
    {value: 14, expirationDate: true, label: "Subsidiary protection beneficiary"},
    {value: 15, expirationDate: true, label: "Asylum (with time limit)"},
    {value: 16, expirationDate: false, label: "Asylum (without time limit)"},
  ],
};

export default function PersonalInformationScreen({
  stepperOptions,
  onNextStep,
  onBackStep,
}) {
  const {
    trigger,
    watch,
    formState: { errors },
    getValues,
    control,
    setValue,
  } = useFormContext();
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  const { countries = [], defaultCountry = {}, lands = [] } = watch();
  const [isClickNext, setClickNext] = useState(false);
  const [telephone, setTelephone] = useState({
    mobil: watch("mobil"),
    privat: watch("privat"),
    geschaeft: watch("geschaeft"),
    weitereTelefonnummer: watch("weitereTelefonnummer"),
    defaultTelephone: "",
  });
  // const teleRef = useRef();
  const isTeleValid =
    watch("mobil").length > 4 ||
    watch("privat").length > 4 ||
    watch("geschaeft").length > 4 ||
    watch("weitereTelefonnummer").length > 4 ||
    watch("telephoneNumber").length > 4;
  const [isShowTeleError, setShowTeleError] = useState(false);
  const { locale } = useLocale();
  const { countryContext } = useCountry();
  const { land } = useLand();
  // const { value } = getValues("land");
  // const [countyOptions, setCountyOptions] = useState([]);
  // const [countryOptions, setCountryOptions] = useState([]);
  // const [deductionAmount, setDeductionAmount] = useState("");

  // the function is called when the county or the language is changed to call counties of the selected county
  // const handleCounty = async () => {
  //   const {
  //     data: { countyList },
  //   } = await getCounty(value);

  //   let tempCountyArr = [];

  //   reorderCountysBySequence(countyList);

  //   for (let county of countyList) {
  //     // eslint-disable-next-line default-case
  //     switch (locale) {
  //       case "de":
  //         tempCountyArr.push({
  //           value: county.ISOcountyCode,
  //           deductionAmount: county.deductionAmount,
  //           label: county.de_county,
  //         });
  //         break;
  //       case "fr":
  //         tempCountyArr.push({
  //           deductionAmount: county.deductionAmount,
  //           value: county.ISOcountyCode,
  //           label: county.fr_county,
  //         });
  //         break;
  //       case "it":
  //         tempCountyArr.push({
  //           deductionAmount: county.deductionAmount,
  //           value: county.ISOcountyCode,
  //           label: county.it_county,
  //         });
  //         break;
  //       case "en":
  //         tempCountyArr.push({
  //           value: county.ISOcountyCode,
  //           deductionAmount: county.deductionAmount,
  //           label: county.en_county,
  //         });
  //         break;
  //     }
  //   }
  //   setCountyOptions(tempCountyArr);
  // };

  const handleKeyDown = e => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  // reorder objects inner array by sequence integer (defined in object)
  // const reorderCountysBySequence = (array) => {
  //   array.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
  // };

  // fetch county when country and language is changed
  // useEffect(() => {
  //   handleCounty();
  // }, [locale, value]);

  // recalculate the total price when county is selected
  // useEffect(() => {
  //   setDeductionAmount(getValues("county").deductionAmount);
  // }, [getValues("county")]);

  // Validate page and go to next page
  const _onNextPage = async () => {
    setClickNext(true);
    if (watch("PersonWeitereTelefonnummer") === "mobil") {
      setTelephone({...telephone, mobil: watch("telephoneNumber")});
    } else if (watch("PersonWeitereTelefonnummer") === "privat") {
      setTelephone({...telephone, privat: watch("telephoneNumber")});
    } else if (watch("PersonWeitereTelefonnummer") === "geschaeft") {
      setTelephone({...telephone, geschaeft: watch("telephoneNumber")});
    } else if (watch("PersonWeitereTelefonnummer") === "weitereTelefonnummer") {
      setTelephone({...telephone, weitereTelefonnummer: watch("telephoneNumber")});
    }
    const isValid = await stepperOptions.triggerForm(2);
    if (!isTeleValid) {
      setShowTeleError(true);
    }
    if ((
      !_.isEqual(_.omit(telephone, ["defaultTelephone"]), {
        mobil: "",
        privat: "",
        geschaeft: "",
        weitereTelefonnummer: "",
      }) || watch("telephoneNumber")) &&
      isValid &&
      isTeleValid
    ) {
      onNextStep();
    }
  };

  // Validate page and go to previous page
  const _onBackStep = async () => {
    await stepperOptions.triggerForm(2);
    onBackStep();
  };

  // Scroll view to top
  useEffect(() => {
    document
      .getElementsByClassName(
        `${isMobile ? "MuiStepLabel-active" : "active"}`
      )[0]
      .scrollIntoView({ behavior: "smooth", inline: "center" });
    window.scrollTo(0, 0);
  }, [isMobile]);

  // Update country language when locale is changed
  useEffect(() => {
    const countries = (countryContext?.returnCountry || []).map((item) => ({
      value: item.isoCode,
      label: item[locale],
    }));
    const lands = (land?.returnCountry || []).map((item) => ({
      value: item.isoCode,
      label: item[locale],
    }));
    const defaultCountryISOCode = "AT";
    const defaultCountry =
      countries.find((item) => item.value === defaultCountryISOCode) || {};
    const translatedCountry = (name) =>
      countries.find((item) => item.value === getValues(name).value) || {};
    const translatedLand = (name) =>
      lands.find((item) => item.value === getValues(name).value) || {};
    const defaultLand =
      lands.find((item) => item.value === getValues("land").value) || {};
    setValue("defaultCountry", defaultCountry);
    setValue("defaultLand", defaultLand);
    setValue("countries", countries);
    setValue("nationalitaet", translatedCountry("nationalitaet"));
    setValue("nationalityKInh", translatedCountry("nationalityKInh"));
    setValue("lands", lands);
    setValue("land", defaultLand);
    setValue("countryKInh", translatedLand("countryKInh"));
    setValue("landKInh", translatedLand("landKInh"));
    console.log("defaultCountry", defaultCountry);
  }, [locale]);

  // Update phone number values
  useEffect(() => {
    if (watch("PersonWeitereTelefonnummer") === "mobil") {
      setValue("mobil", telephone["mobil"]);
    } else if (watch("PersonWeitereTelefonnummer") === "privat") {
      setValue("privat", telephone["privat"]);
    } else if (watch("PersonWeitereTelefonnummer") === "geschaeft") {
      setValue("geschaeft", telephone["geschaeft"]);
    } else if (watch("PersonWeitereTelefonnummer") === "weitereTelefonnummer") {
      setValue("weitereTelefonnummer", telephone["weitereTelefonnummer"]);
    }
  }, [telephone]);

  // Reset data
  useEffect(() => {
    if (getValues("beneficial") === "Nein") {
      [
        "salutationKInh",
        "firstnameKInh",
        "lastnameKInh",
        "countryKInh",
        "nationalityKInh",
        "birthdayKInh",
        "streetKInh",
        "numberKInh",
        "doorNumberKInh",
        "postcodeKInh",
        "placeKInh",
      ].map((e) => {
        setValue(
          e,
          e === "nationalityKInh"
            ? getValues("defaultCountry")
            : e === "countryKInh"
            ? getValues("defaultLand")
            : ""
        );
      });
      if (getValues("naturalPerson") !== "Ja") {
        [
          "companynamejurP",
          "streetjurP",
          "numberjurP",
          "doorNumberjurP",
          "postcodejurP",
          "placejurP",
          "landKInh",
        ].map((e) => {
          setValue(e, e === "landKInh" ? getValues("defaultLand") : "");
        });
      }
    } else {
      if (getValues("naturalPerson") === "Ja") {
        [
          "companynamejurP",
          "streetjurP",
          "numberjurP",
          "doorNumberjurP",
          "postcodejurP",
          "placejurP",
          "landKInh",
        ].map((e) => {
          setValue(e, e === "landKInh" ? getValues("defaultLand") : "");
        });
      } else {
        [
          "salutationKInh",
          "firstnameKInh",
          "lastnameKInh",
          "countryKInh",
          "nationalityKInh",
          "birthdayKInh",
          "streetKInh",
          "numberKInh",
          "doorNumberKInh",
          "postcodeKInh",
          "placeKInh",
        ].map((e) => {
          setValue(
            e,
            e === "nationalityKInh"
              ? getValues("defaultCountry")
              : e === "countryKInh"
              ? getValues("defaultLand")
              : ""
          );
        });
      }
    }
  }, [watch("beneficial"), watch("naturalPerson")]);

  return (
    <>
      <Layout title={<FormattedMessage id="data.title.yourPersonalData" />}
        stepperOptions={stepperOptions}
        onNextStep={_onNextPage}
        onBackStep={_onBackStep}
        footer={{ icon: <LockIcon /> }}
      >
        <div style={{ marginTop: 32 }} />
        <FormBlock>
          <Controller
            name="anrede"
            control={control}
            defaultValue={getValues("anrede")}
            render={({ field }) => {
              return (
                <FormButton
                  isRequired
                  isValid={field.value}
                  isShowError={errors.anrede && errors.anrede.message}
                  label={
                    <FormattedMessage
                      id="data.button.salutation"
                      /* defaultMessage="Anrede" */
                    />
                  }
                  buttons={[
                    {
                      icon: "icon-male",
                      label: (
                        <FormattedMessage
                          id="data.icon.mr"
                          /* defaultMessage="Herr" */
                        />
                      ),
                      value: "Herr",
                    },
                    {
                      icon: "icon-female",
                      label: (
                        <FormattedMessage
                          id="data.icon.mrs"
                          /* defaultMessage="Frau" */
                        />
                      ),
                      value: "Frau",
                    },
                  ]}
                  onClick={(e, value) => {
                    field.onChange(value);
                  }}
                  {...field}
                />
              );
            }}
          />
          <Controller
            name="vorname"
            control={control}
            defaultValue={getValues("vorname")}
            render={({ field }) => {
              return (
                <FormInput
                  isValid={watch("vorname") && !errors.vorname}
                  isRequired
                  label={<FormattedMessage id="data.input.name" />}
                  {...field}
                  isShowError={
                    errors.vorname?.message && errors.vorname?.message
                  }
                />
              );
            }}
          />
          <Controller
            name="nachname"
            control={control}
            defaultValue={getValues("nachname")}
            render={({ field }) => {
              return (
                <FormInput
                  isValid={watch("nachname") && !errors.nachname}
                  isRequired
                  label={<FormattedMessage id="data.input.surname" />}
                  {...field}
                  // errorMessage={errors.nachname?.message && errors.nachname?.message}
                  isShowError={
                    errors.nachname?.message && errors.nachname?.message
                  }
                />
              );
            }}
          />
          <Controller
            name="geburtstag"
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => {
              return (
                <>
                  <InputRow
                    label={<FormattedMessage id="data.input.birthday" />}
                    isRequired
                    isValid={value}
                    tooltip={
                      // <FormattedMessage
                      //   id="data.input.birthday.tooltip"
                      // />
                      <FormattedMessage id="common.date.format" />
                    }
                    isShowError={
                      errors.geburtstag && errors.geburtstag.message
                    }>
                    <div className="row d-flex align-items-center">
                      <Col md={4}>
                        <DatepickerComponent
                          data-cy="birthday1DatePicker"
                          isDoB
                          //value={value}
                          value={value ? new Date(value) : value}
                          onChange={(e) => {
                            onChange(e);
                            onBlur(e); //TODO: Trick for validate (mode: "onBlur")
                          }}
                          onBlur={onBlur}
                        />
                      </Col>
                    </div>
                  </InputRow>
                </>
              );
            }}
          />
          <Controller
            name="zivilstand"
            control={control}
            render={({ field }) => {
              return (
                <FormButton
                  isCenter={isMobile}
                  moreSpace
                  isShowError={errors.zivilstand && errors.zivilstand.message}
                  isRequired
                  isValid={field.value}
                  label={<FormattedMessage id="data.input.civilstatus" />}
                  buttons={[
                    {
                      icon: "icon-person-heart",
                      label: <FormattedMessage id="data.icon.single" />,
                      value: "Ledig",
                    },
                    {
                      icon: "icon-person-group",
                      label: <FormattedMessage id="data.icon.married" />,
                      value: "Verheiratet",
                    },
                    {
                      icon: "icon-widowed",
                      label: <FormattedMessage id="data.icon.widowed" />,
                      value: "Verwitwet",
                    },
                    {
                      icon: "icon-divorced",
                      label: <FormattedMessage id="data.icon.divorced" />,
                      value: "Geschieden",
                    },
                    // {
                    //   icon: "icon-hammer",
                    //   label: <FormattedMessage id="data.icon.seperated" />,
                    //   value: "Richterlich getrennt",
                    // },
                  ]}
                  onClick={(e, value) => {
                    field.onChange(value);
                  }}
                  {...field}
                />
              );
            }}
          />
          <Controller
            name="nationalitaet"
            isClearable
            control={control}
            render={({ field }) => (
              <InputRow
                isShowError={
                  errors.nationalitaet && errors.nationalitaet.message
                }
                label={<FormattedMessage id="data.input.nationality" />}
                isRequired
                isValid={field.value}>
                <Select
                  placeholder={<FormattedMessage id="common.date.year" />}
                  {...field}
                  isSearchable
                  options={countries.map((e) =>
                    e.value === ""
                      ? { value: e.value, label: e.label, isDisabled: true }
                      : e
                  )}
                  styles={CUSTOM_STYLE}
                />
              </InputRow>
            )}
          />
          <Controller
            name="residencePermit"
            isClearable
            control={control}
            render={({ field }) => {
              return (
                !_.isEqual(watch("nationalitaet"), defaultCountry) && (
                  <InputRow
                    isShowError={errors.residencePermit && errors.residencePermit.message}
                    isValid={field.value}
                    label={<FormattedMessage id="data.input.residencePermit" />}
                    isRequired>
                    <Select
                      placeholder={<FormattedMessage id="data.placeholder.residencePermit" />}
                      {...field}
                      isSearchable
                      options={EXPATRIATE_LIST[String(locale).toLowerCase()].map((e) => e.value === "" ? { value: e.value, label: e.label, isDisabled: true } : e)}
                      styles={CUSTOM_STYLE}
                    />
                  </InputRow>
                )
              );
            }}
          />
          <Controller
            name="residencePermitDate"
            isClearable
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => {
              return (
                !_.isEqual(watch("nationalitaet"), defaultCountry) && 
                _.isEqual(watch("residencePermit").expirationDate, true) && (
                  <InputRow
                    label={<FormattedMessage id="data.input.residencePermitDate" />}
                    isRequired
                    isValid={value}
                    isShowError={errors.residencePermitDate && errors.residencePermitDate.message}>
                    <div className="row d-flex align-items-center">
                      <Col md={4}>
                        <DatepickerComponent
                          data-cy="birthday1DatePicker"
                          isMinDateNextDay
                          //value={value}
                          value={value ? new Date(value) : value}
                          onChange={(e) => {
                            onChange(e);
                            onBlur(e);
                          }}
                          onBlur={onBlur}
                        />
                      </Col>
                    </div>
                  </InputRow>
                )
              );
            }}
          />
        </FormBlock>
        <FormBlock title={<FormattedMessage id="data.title.yourResidence" />}>
          <InputRow
            label={
              <>
                <FormattedMessage
                  id="data.input.addressStreet"
                />
                <sup class="required"> *</sup>{" / "}
                <FormattedMessage
                  id="data.input.addressNumber"
                />
                <sup class="required"> *</sup>{" / "}
                <FormattedMessage
                  id="data.input.addressDoor"
                />
              </>
            }
            isValid={
              watch("strasse") &&
                watch("nummer") &&
                !errors.strasse &&
                !errors.nummer
            }
            isShowError={
              errors.strasse
                ? errors.strasse.message
                : errors.nummer
                ? errors.nummer.message
                : null
            }>
            <Row>
              <Col md={8} sm={8} xs={8}>
                <Controller
                  name="strasse"
                  control={control}
                  defaultValue={getValues("strasse")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        className="input-shadow"
                        required
                        {...field}
                        onChange={(event) => {
                          setValue("strasse", event.target.value);
                        }}
                        onBlur={async () => {
                          field.onBlur();
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col md={2} sm={2} xs={2}>
                <Controller
                  name="nummer"
                  control={control}
                  defaultValue={getValues("nummer")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        className="input-shadow"
                        required
                        {...field}
                        onChange={(event) => {
                          setValue("nummer", event.target.value);
                        }}
                        onBlur={async () => {
                          field.onBlur();
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col md={2} sm={2} xs={2}>
                <Controller
                  name="doorNumber"
                  control={control}
                  defaultValue={getValues("doorNumber")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        className="input-shadow"
                        {...field}
                        onChange={(event) => {
                          setValue("doorNumber", event.target.value);
                        }}
                        onBlur={async () => {
                          field.onBlur();
                        }}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
            <Row>
              {/* <Col md={12} className="text-danger">{errors.strasse ? errors.strasse.message : errors.nummer ? errors.nummer.message : null}</Col> */}
            </Row>
          </InputRow>
          <InputRow
            label={<FormattedMessage id="data.input.postcode" /* defaultMessage="PLZ/Ort" */ />}
            isRequired
            isValid={watch("plz") && watch("ort") && !errors.plz && !errors.ort}
            isShowError={errors.plz ? errors.plz.message : errors.ort ? errors.ort.message : null}>
            <Row>
              <Col md={4} xs={4} sm={4}>
                <Controller
                  name="plz"
                  control={control}
                  defaultValue={getValues("plz")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        inputMode="numeric"
                        className="input-shadow"
                        required
                        {...field}
                        onChange={(event) => {
                          event.preventDefault();
                          if (!_.isNaN(Number(event.target.value))) {
                            setValue("plz", event.target.value);
                          }
                        }}
                        onBlur={async () => {
                          field.onBlur();
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col md={8} xs={8} sm={8}>
                <Controller
                  name="ort"
                  control={control}
                  defaultValue={getValues("ort")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        className="input-shadow"
                        required
                        {...field}
                        onBlur={async () => {
                          field.onBlur();
                        }}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
            <Row></Row>
          </InputRow>
          <Controller
            name="land"
            isClearable
            control={control}
            render={({ field }) => (
              <InputRow
                isShowError={errors.land && errors.land.message}
                isValid={field.value}
                label={<FormattedMessage id="data.input.country" />}
                isRequired>
                <Select
                  placeholder={<FormattedMessage id="common.date.year" />}
                  {...field}
                  isSearchable
                  // options={lands.map((e) => e.value === "" ? {value: e.value, label: e.label, isDisabled: true,} : e)}
                  options={COUNTRY_LIST[String(locale).toLowerCase()].map((e) =>
                    e.value === ""
                      ? { value: e.value, label: e.label, isDisabled: true }
                      : e
                  )}
                  styles={CUSTOM_STYLE}
                />
              </InputRow>
            )}
          />
          <Controller
            name="wohnhaftSeit"
            isClearable
            control={control}
            render={({ field }) => (
              <InputRow
                isShowError={!!errors.wohnhaftSeit}
                isValid={field.value && !errors.wohnhaftSeit}
                label={
                  <FormattedMessage
                    id="data.input.addressSince"
                    /* defaultMessage="An dieser Adresse wohnhaft seit" */
                  />
                }
                isRequired>
                <div className="w-180">
                  <Select
                    placeholder={<FormattedMessage id="common.date.year" />}
                    isSearchable
                    options={years}
                    styles={CUSTOM_STYLE}
                    {...field}
                  />
                </div>

                {/* <Col md={12} className="text-danger p-0">{errors.wohnhaftSeit && formatMessage({ id: "validation.text.required" })}</Col> */}
              </InputRow>
            )}
          />
        </FormBlock>
        <FormBlock title={<FormattedMessage id="data.title.contactDetails" />}>
          <Controller
            name="PersonWeitereTelefonnummer"
            control={control}
            defaultValue={getValues("PersonWeitereTelefonnummer")}
            render={({ field }) => {
              return (
                <Col>
                  <FormButton
                    isShowStatus={false}
                    isShowError={field.value === "defaultTelephone"}
                    isValid={field.value !== "defaultTelephone"}
                    buttons={[
                      {
                        icon: "icon-mobile",
                        label: (
                          <FormattedMessage
                            id="data.icon.mobile"
                            /* defaultMessage="Mobile" */
                          />
                        ),
                        value: "mobil",
                      },
                      {
                        icon: "icon-phone",
                        label: (
                          <FormattedMessage
                            id="data.icon.private"
                            /* defaultMessage="Privat" */
                          />
                        ),
                        value: "privat",
                      },
                      {
                        icon: "icon-telephone",
                        label: (
                          <FormattedMessage
                            id="data.icon.business"
                            /* defaultMessage="Geschäft" */
                          />
                        ),
                        value: "geschaeft",
                      },
                      {
                        icon: "icon-plus",
                        label: (
                          <FormattedMessage
                            id="data.icon.more"
                            /* defaultMessage="Weitere" */
                          />
                        ),
                        value: "weitereTelefonnummer",
                      },
                    ]}
                    onClick={(e, value) => {
                      if (
                        _.isEqual(
                          watch("PersonWeitereTelefonnummer"),
                          "defaultTelephone"
                        ) &&
                        !_.isEmpty(
                          telephone[watch("PersonWeitereTelefonnummer")]
                        )
                      ) {
                        if (value === "mobil") {
                          setTelephone({...telephone, mobil: telephone[watch("PersonWeitereTelefonnummer")],});
                        } else if (value === "privat") {
                          setTelephone({...telephone, privat: telephone[watch("PersonWeitereTelefonnummer")],});
                        } else if (value === "geschaeft") {
                          setTelephone({...telephone, geschaeft: telephone[watch("PersonWeitereTelefonnummer")],});
                        } else if (value === "weitereTelefonnummer") {
                          setTelephone({...telephone, weitereTelefonnummer: telephone[watch("PersonWeitereTelefonnummer")],});
                        }
                      }
                      field.onChange(value);
                      setTelephone((val) => {
                        return {
                          ...val,
                          defaultTelephone: "",
                        };
                      });
                      trigger(
                        [
                          "mobil",
                          "privat",
                          "geschaeft",
                          "weitereTelefonnummer",
                        ],
                        { shouldFocus: true }
                      );
                    }}
                    {...field}
                  />
                </Col>
              );
            }}
          />
          {/* <InputRow
            isValid={isTeleValid}
            isShowError={!isTeleValid && isShowTeleError}
            label={
              <FormattedMessage
                id="data.input.phone"
              />
            }
            isRequired
            tooltip={
              <FormattedMessage
                id="data.input.teleformat"
              />
            }>
            <Row className="d-flex align-items-center">
              <Col md={8} xs={12}>
                <Controller
                  name={watch("PersonWeitereTelefonnummer")}
                  control={control}
                  render={({ field }) => {
                    return (
                      <>
                        <Form.Control
                          inputMode="tel"
                          className="input-shadow"
                          required
                          {...field}
                          value={telephone[watch("PersonWeitereTelefonnummer")]}
                          onChange={(event) => {
                            event.preventDefault();
                            if (!_.isNaN(Number(event.target.value))) {
                              if (getValues("PersonWeitereTelefonnummer") === "mobil") {
                                setTelephone({...telephone, mobil: event.target.value,});
                              } else if (getValues("PersonWeitereTelefonnummer") === "privat") {
                                setTelephone({...telephone, privat: event.target.value,});
                              } else if (getValues("PersonWeitereTelefonnummer") === "geschaeft") {
                                setTelephone({...telephone, geschaeft: event.target.value,});
                              } else if (getValues("PersonWeitereTelefonnummer") === "weitereTelefonnummer") {
                                setTelephone({...telephone, weitereTelefonnummer: event.target.value,});
                              } else if (getValues("PersonWeitereTelefonnummer") === "defaultTelephone") {
                                setTelephone({...telephone, defaultTelephone: event.target.value,});
                              }
                            }
                          }}
                          onKeyDown={handleKeyDown}
                          ref={teleRef}
                          onBlur={() => {
                            if (!isTeleValid) {
                              setShowTeleError(true);
                            }
                          }}
                        />
                      </>
                    );
                  }}
                />
              </Col>
            </Row>
          </InputRow> */}
          <InputRow
            label={<FormattedMessage id="data.input.phone" />}
            tooltip={<FormattedMessage id="data.input.teleformat" />}
            isValid={watch("telephoneNumber") && !errors.telephoneNumber}
            isShowError={errors.telephoneNumber ? errors.telephoneNumber.message : null}
            isRequired>
            <Row className="d-flex align-items-center">
              <Col md={8} xs={12}>
                <Controller
                  name="telephoneNumber"
                  control={control}
                  defaultValue={getValues("telephoneNumber")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        inputMode="tel"
                        className="input-shadow"
                        required
                        {...field}
                        onChange={(event) => {
                          event.preventDefault();
                          setValue("telephoneNumber", event.target.value);
                        }}
                        onBlur={async () => {
                          field.onBlur();
                        }}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
          </InputRow>
          <Controller
            name="email"
            control={control}
            // defaultValue={getValues("email")}
            render={({ field }) => {
              return (
                <FormInput
                  isShowError={!!errors.email?.message}
                  isValid={watch("email") && !errors.email}
                  isRequired
                  label={
                    <FormattedMessage
                      id="data.input.email"
                      /* defaultMessage="E-Mail-Adresse" */
                    />
                  }
                  {...field}
                />
              );
            }}
          />
          <FormBlock
            title={<FormattedMessage id="data.title.beneficialOwner" />}>
            <Controller
              name="beneficial"
              control={control}
              defaultValue={getValues("beneficial")}
              render={({ field }) => {
                return (
                  <FormButton
                    isLeft
                    isRequired
                    isValid={field.value}
                    isShowError={errors.beneficial && errors.beneficial.message}
                    label={<FormattedMessage id="data.button.beneficial" />}
                    // tooltip={
                    //   <FormattedMessage id="data.button.beneficial.tooltip" />
                    // }
                    buttons={[
                      {
                        text: (
                          <FormattedMessage id="data.button.beneficial_yes" />
                        ),
                        value: "Ja",
                      },
                      {
                        text: (
                          <FormattedMessage id="data.button.beneficial_no" />
                        ),
                        value: "Nein",
                      },
                    ]}
                    onClick={(e, value) => {
                      field.onChange(value);
                    }}
                    {...field}
                  />
                );
              }}
            />
            {watch("beneficial") === "Ja" && (
              <>
                <Controller
                  name="naturalPerson"
                  control={control}
                  defaultValue={getValues("naturalPerson")}
                  render={({ field }) => {
                    return (
                      <FormButton
                        moreWidth
                        isLeft
                        isRequired
                        isValid={field.value}
                        isShowError={
                          errors.naturalPerson && errors.naturalPerson.message
                        }
                        label={
                          <FormattedMessage id="data.title.whoIsBeneficialOwner" />
                        }
                        buttons={[
                          {
                            text: (
                              <FormattedMessage id="data.button.naturalPerson2" />
                            ),
                            value: "Ja",
                          },
                          {
                            text: (
                              <FormattedMessage id="data.button.legalPerson2" />
                            ),
                            value: "Nein",
                          },
                        ]}
                        onClick={(e, value) => {
                          field.onChange(value);
                        }}
                        {...field}
                      />
                    );
                  }}
                />
                {watch("naturalPerson") !== "Ja" && (
                  <>
                    <Controller
                      name="companynamejurP"
                      control={control}
                      defaultValue={getValues("companynamejurP")}
                      render={({ field }) => {
                        return (
                          <FormInput
                            isValid={
                              watch("companynamejurP") &&
                              !errors.companynamejurP
                            }
                            isRequired
                            label={<FormattedMessage id="data.input.company" />}
                            {...field}
                            // errorMessage={errors.arbeitgeber?.message && errors.arbeitgeber?.message}
                            isShowError={!!errors.companynamejurP?.message}
                          />
                        );
                      }}
                    />
                    <InputRow
                      label={
                        <>
                          <FormattedMessage
                            id="data.input.addressStreet"
                          />
                          <sup class="required"> *</sup>{" / "}
                          <FormattedMessage
                            id="data.input.addressNumber"
                          />
                          <sup class="required"> *</sup>{" / "}
                          <FormattedMessage
                            id="data.input.addressDoor"
                          />
                        </>
                      }
                      isValid={
                        watch("landKInh").value !== "AT" ||
                        (watch("streetjurP") &&
                          watch("numberjurP") &&
                          !errors.streetjurP &&
                          !errors.numberjurP)
                      }
                      isShowError={
                        watch("landKInh").value === "AT" &&
                        (errors.streetjurP
                          ? errors.streetjurP.message
                          : errors.numberjurP
                          ? errors.numberjurP.message
                          : null)
                      }>
                      <Row>
                        <Col md={8} sm={8} xs={8}>
                          <Controller
                            name="streetjurP"
                            control={control}
                            defaultValue={getValues("streetjurP")}
                            render={({ field }) => {
                              return (
                                <Form.Control
                                  className="input-shadow"
                                  required
                                  {...field}
                                  onChange={(event) => {
                                    setValue("streetjurP", event.target.value);
                                  }}
                                  onBlur={async () => {
                                    field.onBlur();
                                  }}
                                />
                              );
                            }}
                          />
                        </Col>
                        <Col md={2} sm={2} xs={2}>
                          <Controller
                            name="numberjurP"
                            control={control}
                            defaultValue={getValues("numberjurP")}
                            render={({ field }) => {
                              return (
                                <Form.Control
                                  className="input-shadow"
                                  required
                                  {...field}
                                  onChange={(event) => {
                                    setValue("numberjurP", event.target.value);
                                  }}
                                  onBlur={async () => {
                                    field.onBlur();
                                  }}
                                />
                              );
                            }}
                          />
                        </Col>
                        <Col md={2} sm={2} xs={2}>
                          <Controller
                            name="doorNumberjurP"
                            control={control}
                            defaultValue={getValues("doorNumberjurP")}
                            render={({ field }) => {
                              return (
                                <Form.Control
                                  className="input-shadow"
                                  {...field}
                                  onChange={(event) => {
                                    setValue("doorNumberjurP", event.target.value);
                                  }}
                                  onBlur={async () => {
                                    field.onBlur();
                                  }}
                                />
                              );
                            }}
                          />
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col md={12} className="text-danger">{errors.strasseArbeitgeber ? errors.strasseArbeitgeber.message : errors.hausnummerArbeitgeber ? errors.hausnummerArbeitgeber.message : null}</Col>
                      </Row> */}
                    </InputRow>
                    <InputRow
                      label={
                        <FormattedMessage
                          id="data.input.postcode"
                        />
                      }
                      isRequired
                      isValid={
                        watch("postcodejurP") &&
                        watch("placejurP") &&
                        !errors.postcodejurP &&
                        !errors.placejurP
                      }
                      isShowError={
                        errors.postcodejurP
                          ? errors.postcodejurP.message
                          : errors.placejurP
                          ? errors.placejurP.message
                          : null
                      }>
                      <Row>
                        <Col md={4} xs={4} sm={4}>
                          <Controller
                            name="postcodejurP"
                            control={control}
                            defaultValue={getValues("postcodejurP")}
                            render={({ field }) => {
                              return (
                                <Form.Control
                                  inputMode="numeric"
                                  className="input-shadow"
                                  required
                                  {...field}
                                  onChange={(event) => {
                                    event.preventDefault();
                                    if (!_.isNaN(Number(event.target.value))) {
                                      setValue(
                                        "postcodejurP",
                                        event.target.value
                                      );
                                    }
                                  }}
                                />
                              );
                            }}
                          />
                        </Col>
                        <Col md={8} xs={8} sm={8}>
                          <Controller
                            name="placejurP"
                            control={control}
                            defaultValue={getValues("placejurP")}
                            render={({ field }) => {
                              return (
                                <Form.Control
                                  className="input-shadow"
                                  required
                                  {...field}
                                />
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </InputRow>
                    <Controller
                      name="landKInh"
                      isClearable
                      control={control}
                      render={({ field }) => (
                        <InputRow
                          isShowError={
                            errors.landKInh && errors.landKInh.message
                          }
                          isValid={field.value}
                          label={<FormattedMessage id="data.input.country" />}
                          isRequired>
                          <Select
                            placeholder={<FormattedMessage id="common.date.year" />}
                            {...field}
                            isSearchable
                            // options={lands.map((e) => e.value === "" ? {value: e.value, label: e.label, isDisabled: true,} : e)}
                            options={COUNTRY_LIST[String(locale).toLowerCase()].map((e) =>
                              e.value === "" ? { value: e.value, label: e.label, isDisabled: true } : e
                            )}
                            styles={CUSTOM_STYLE}
                          />
                        </InputRow>
                      )}
                    />
                  </>
                )}
                <FormBlock
                  title={
                    watch("naturalPerson") !== "Ja" && (
                      <FormattedMessage id="data.title.controllingPerson" />
                    )
                  }>
                  <Controller
                    name="salutationKInh"
                    control={control}
                    defaultValue={getValues("salutationKInh")}
                    render={({ field }) => {
                      return (
                        <FormButton
                          isRequired
                          isValid={field.value}
                          isShowError={errors.anrede && errors.anrede.message}
                          label={
                            <FormattedMessage
                              id="data.button.salutation"
                              /* defaultMessage="Anrede" */
                            />
                          }
                          buttons={[
                            {
                              icon: "icon-male",
                              label: (
                                <FormattedMessage
                                  id="data.icon.mr"
                                  /* defaultMessage="Herr" */
                                />
                              ),
                              value: "Herr",
                            },
                            {
                              icon: "icon-female",
                              label: (
                                <FormattedMessage
                                  id="data.icon.mrs"
                                  /* defaultMessage="Frau" */
                                />
                              ),
                              value: "Frau",
                            },
                          ]}
                          onClick={(e, value) => {
                            field.onChange(value);
                          }}
                          {...field}
                        />
                      );
                    }}
                  />
                  <Controller
                    name="firstnameKInh"
                    control={control}
                    defaultValue={getValues("firstnameKInh")}
                    render={({ field, fieldState }) => {
                      return (
                        <FormInput
                          isValid={
                            watch("firstnameKInh") && !errors.firstnameKInh
                          }
                          isRequired
                          label={<FormattedMessage id="data.input.name" />}
                          {...field}
                          isShowError={
                            errors.firstnameKInh?.message &&
                            errors.firstnameKInh?.message
                          }
                        />
                      );
                    }}
                  />
                  <Controller
                    name="lastnameKInh"
                    control={control}
                    defaultValue={getValues("lastnameKInh")}
                    render={({ field, fieldState }) => {
                      return (
                        <FormInput
                          isValid={
                            watch("lastnameKInh") && !errors.lastnameKInh
                          }
                          isRequired
                          label={<FormattedMessage id="data.input.surname" />}
                          {...field}
                          // errorMessage={errors.nachname?.message && errors.nachname?.message}
                          isShowError={
                            errors.lastnameKInh?.message &&
                            errors.lastnameKInh?.message
                          }
                        />
                      );
                    }}
                  />
                  <Controller
                    name="birthdayKInh"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                      return (
                        <>
                          <InputRow
                            label={
                              <FormattedMessage id="data.input.birthday" />
                            }
                            isRequired
                            isValid={value}
                            tooltip={
                              <FormattedMessage id="common.date.format" />
                            }
                            isShowError={
                              errors.birthdayKInh && errors.birthdayKInh.message
                            }>
                            <div className="row d-flex align-items-center">
                              <Col md={4}>
                                <DatepickerComponent
                                  isDoB
                                  //value={value}
                                  value={value ? new Date(value) : value}
                                  onChange={(e) => {
                                    onChange(e);
                                    onBlur(e); //TODO: Trick for validate (mode: "onBlur")
                                  }}
                                  onBlur={onBlur}
                                />
                              </Col>
                            </div>
                          </InputRow>
                        </>
                      );
                    }}
                  />
                  <Controller
                    name="nationalityKInh"
                    isClearable
                    control={control}
                    render={({ field }) => (
                      <InputRow
                        isShowError={
                          errors.nationalityKInh &&
                          errors.nationalityKInh.message
                        }
                        label={<FormattedMessage id="data.input.nationality" />}
                        isRequired
                        isValid={field.value}>
                        <Select
                          placeholder={
                            <FormattedMessage id="common.date.year" />
                          }
                          {...field}
                          isSearchable
                          options={countries.map((e) =>
                            e.value === ""
                              ? {
                                  value: e.value,
                                  label: e.label,
                                  isDisabled: true,
                                }
                              : e
                          )}
                          styles={CUSTOM_STYLE}
                        />
                      </InputRow>
                    )}
                  />
                  <Controller
                    name="residencePermitKInh"
                    isClearable
                    control={control}
                    render={({ field }) => {
                      return (
                        !_.isEqual(watch("nationalityKInh"), defaultCountry) && (
                          <InputRow
                            isShowError={errors.residencePermitKInh && errors.residencePermitKInh.message}
                            isValid={field.value}
                            label={<FormattedMessage id="data.input.residencePermit" />}
                            isRequired>
                            <Select
                              placeholder={<FormattedMessage id="data.placeholder.residencePermit" />}
                              {...field}
                              isSearchable
                              options={EXPATRIATE_LIST[String(locale).toLowerCase()].map((e) => e.value === "" ? { value: e.value, label: e.label, isDisabled: true } : e)}
                              styles={CUSTOM_STYLE}
                            />
                          </InputRow>
                        )
                      );
                    }}
                  />
                  <Controller
                    name="residencePermitDateKInh"
                    isClearable
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                      return (
                        !_.isEqual(watch("nationalityKInh"), defaultCountry) && 
                        _.isEqual(watch("residencePermitKInh").expirationDate, true) && (
                          <InputRow
                            label={<FormattedMessage id="data.input.residencePermitDate" />}
                            isRequired
                            isValid={value}
                            isShowError={errors.residencePermitDateKInh && errors.residencePermitDateKInh.message}>
                            <div className="row d-flex align-items-center">
                              <Col md={4}>
                                <DatepickerComponent
                                  data-cy="birthday1DatePicker"
                                  isMinDateNextDay
                                  //value={value}
                                  value={value ? new Date(value) : value}
                                  onChange={(e) => {
                                    onChange(e);
                                    onBlur(e);
                                  }}
                                  onBlur={onBlur}
                                />
                              </Col>
                            </div>
                          </InputRow>
                        )
                      );
                    }}
                  />
                  <InputRow
                    label={
                      <>
                        <FormattedMessage
                          id="data.input.addressStreet"
                        />
                        <sup class="required"> *</sup>{" / "}
                        <FormattedMessage
                          id="data.input.addressNumber"
                        />
                        <sup class="required"> *</sup>{" / "}
                        <FormattedMessage
                          id="data.input.addressDoor"
                        />
                      </>
                    }
                    isValid={
                      watch("streetKInh") &&
                        watch("numberKInh") &&
                        !errors.streetKInh &&
                        !errors.numberKInh
                    }
                    isShowError={
                      errors.streetKInh
                        ? errors.streetKInh.message
                        : errors.numberKInh
                        ? errors.numberKInh.message
                        : null
                    }>
                    <Row>
                      <Col md={8} sm={8} xs={8}>
                        <Controller
                          name="streetKInh"
                          control={control}
                          defaultValue={getValues("streetKInh")}
                          render={({ field }) => {
                            return (
                              <Form.Control
                                className="input-shadow"
                                required
                                {...field}
                                onChange={(event) => {
                                  setValue("streetKInh", event.target.value);
                                }}
                                onBlur={async () => {
                                  field.onBlur();
                                }}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col md={2} sm={2} xs={2}>
                        <Controller
                          name="numberKInh"
                          control={control}
                          defaultValue={getValues("numberKInh")}
                          render={({ field }) => {
                            return (
                              <Form.Control
                                className="input-shadow"
                                required
                                {...field}
                                onChange={(event) => {
                                  setValue("numberKInh", event.target.value);
                                }}
                                onBlur={async () => {
                                  field.onBlur();
                                }}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col md={2} sm={2} xs={2}>
                        <Controller
                          name="doorNumberKInh"
                          control={control}
                          defaultValue={getValues("doorNumberKInh")}
                          render={({ field }) => {
                            return (
                              <Form.Control
                                className="input-shadow"
                                {...field}
                                onChange={(event) => {
                                  setValue("doorNumberKInh", event.target.value);
                                }}
                                onBlur={async () => {
                                  field.onBlur();
                                }}
                              />
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </InputRow>
                  <InputRow
                    label={
                      <FormattedMessage
                        id="data.input.postcode"
                      />
                    }
                    isRequired
                    isValid={
                      watch("postcodeKInh") &&
                      watch("placeKInh") &&
                      !errors.postcodeKInh &&
                      !errors.placeKInh
                    }
                    isShowError={
                      errors.postcodeKInh
                        ? errors.postcodeKInh.message
                        : errors.placeKInh
                        ? errors.placeKInh.message
                        : null
                    }>
                    <Row>
                      <Col md={4} xs={4} sm={4}>
                        <Controller
                          name="postcodeKInh"
                          control={control}
                          defaultValue={getValues("postcodeKInh")}
                          render={({ field }) => {
                            return (
                              <Form.Control
                                inputMode="numeric"
                                className="input-shadow"
                                required
                                {...field}
                                onChange={(event) => {
                                  event.preventDefault();
                                  if (!_.isNaN(Number(event.target.value))) {
                                    setValue(
                                      "postcodeKInh",
                                      event.target.value
                                    );
                                  }
                                }}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col md={8} xs={8} sm={8}>
                        <Controller
                          name="placeKInh"
                          control={control}
                          defaultValue={getValues("placeKInh")}
                          render={({ field }) => {
                            return (
                              <Form.Control
                                className="input-shadow"
                                required
                                {...field}
                              />
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </InputRow>
                  <Controller
                    name="countryKInh"
                    isClearable
                    control={control}
                    render={({ field }) => (
                      <InputRow
                        isShowError={
                          errors.countryKInh && errors.countryKInh.message
                        }
                        isValid={field.value}
                        label={
                          <FormattedMessage
                            id="data.input.country"
                          />
                        }
                        isRequired
                        tooltip={
                          <FormattedMessage
                            id="data.input.land.tooltip"
                          />
                        }>
                        <Select
                          placeholder={
                            <FormattedMessage id="common.date.year" />
                          }
                          {...field}
                          isSearchable
                          // options={lands.map((e) => e.value === "" ? {value: e.value, label: e.label, isDisabled: true,} : e)}
                          options={COUNTRY_LIST[String(locale).toLowerCase()].map((e) =>
                            e.value === ""
                              ? { value: e.value, label: e.label, isDisabled: true }
                              : e
                          )}
                          styles={CUSTOM_STYLE}
                        />
                      </InputRow>
                    )}
                  />
                </FormBlock>
              </>
            )}
          </FormBlock>
        </FormBlock>
      </Layout>
    </>
  );
}
