import ButtonComponent from "components/button/buttonComponent";
import Header from "components/Header";
import { CONCLUDE_BUTTON_STATUS, RESPONSIVE } from "constant";
import { useLocale } from "context/locale";
import { format } from "currency-formatter";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Document, Page, pdfjs } from "react-pdf";
import { useMediaQuery } from "react-responsive";
import { getCarModel, getStar, isTrue } from "utils";
import { getTerm, postBusinessConsent } from "utils/api";
import LogoIcon from "../../../assets/icons/LogoIcon.png";
import "./Confirmation.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Detail = ({ data = { model: "" }, isMobile }) => {
  const { formatMessage } = useIntl();
  const star = getStar(
    data["responseText1"],
    data["responseText2"],
    data["responseText4"]
  );
  return (
    <>
      <Col
        xs={12}
        sm={12}
        md={5}
        lg={5}
        className={`justify-content-center confirmation-information-box d-flex ${
          isMobile ? "p-4" : ""
        }`}>
        <div className="align-self-center w-100">
          <div
            className={`confirmation-label-model `}
            style={isMobile ? { fontSize: "14pt" } : {}}>
            {formatMessage({ id: "confirmation.text.modell" })} :{" "}
            {data["model"]}
          </div>
          <div
            className={`${isMobile ? "mb-2" : "mb-4"}`}
            style={{ fontSize: "18pt" }}>
            {formatMessage({ id: "confirmation.text.orderID" })} :{" "}
            {data["OrderID"]}
          </div>
          <Row className="text-row">
            <Col>{formatMessage({ id: "confirmation.text.preis" })}:</Col>
            <Col className="d-flex justify-content-center ">
              {`${format(data["Price"], { thousand: ".", decimal: ",", precision: 0 })} `}
              {formatMessage({ id: "common.text.money" })}
            </Col>
          </Row>

          <Row className="text-row">
            <Col>{formatMessage({ id: "confirmation.text.laufzeit" })}:</Col>
            <Col className="d-flex justify-content-center ">
              {`${data["Laufzeit"]} `}
              {formatMessage({ id: "confirmation.text.months" })}{" "}
              {star["laufzeit"] && star["laufzeit"]}
            </Col>
          </Row>

          <Row className="text-row">
            <Col>
              {formatMessage({ id: "confirmation.text.laufleistung" })}:
            </Col>
            <Col className="d-flex justify-content-center ">
              {`${format(data["Laufleistung"], {
                thousand: ".",
                decimal: ",",
                precision: 0,
              })} `}
              {formatMessage({ id: "confirmation.text.laufleistung.km" })}
            </Col>
          </Row>

          <Row className="text-row">
            <Col>{formatMessage({ id: "confirmation.text.anzahlung" })}:</Col>
            <Col className="d-flex justify-content-center align-items-center ">
              {`${format(data["Anzahlung"], { thousand: ".", decimal: ",", precision: 0 })} `}
              {formatMessage({ id: "common.text.money" })}{" "}
              {star["grosse"] && star["grosse"]}
            </Col>
          </Row>

          <Row className="text-row">
            <Col>{formatMessage({ id: "confirmation.text.restwert" })}:</Col>
            <Col className="d-flex justify-content-center ">
              {`${format(data["Restwert"], { thousand: ".", decimal: ",", precision: 0 })} `}
              {formatMessage({ id: "common.text.money" })}{" "}
              {star["restwert"] && star["restwert"]}
            </Col>
          </Row>

          <Row className="text-row mt-4">
            <Col>
              <div>
                {formatMessage({ id: "confirmation.text.leasingrate" })}
                <br />
                {formatMessage({ id: "common.text.inchf" })}
              </div>
            </Col>
            <Col>
              <div
                className="d-flex font-weight-bold custom-color-input justify-content-center"
                style={{
                  fontSize: "1.5rem",
                  lineHeight: 1.5,
                  padding: ".5rem 1rem",
                }}>
                {`${format(data["Leasingrate"], {
                  thousand: ".",
                  decimal: ",",
                  precision: 2,
                })} `}
                {formatMessage({ id: "common.text.money" })}
              </div>
            </Col>
          </Row>

          <Row className="text-row mt-4">
            <Col>
              {`${formatMessage({
                id: "confirmation.text.nomZins",
              })} ${format(data["nomInterest"], {
                decimal: ".",
              })}% ${formatMessage({ id: "common.text.pa" })} (${formatMessage({
                id: "confirmation.text.effZins",
              })} ${format(data["effInterest"], {
                decimal: ".",
                precision: 4,
              })}% ${formatMessage({
                id: "common.text.pa",
              })})`}
            </Col>
          </Row>

          <Row className="text-row mt-4">
            {star["val1"] && (
              <Col md={12}>
                {star["val1"]}
                {formatMessage(
                  { id: "confirmation.text.responseText1" },
                  { response: Number(data["responseText1"]) + data["Laufzeit"] }
                )}
              </Col>
            )}
            {star["val4"] && (
              <Col md={12}>
                {star["val4"]}
                {formatMessage(
                  { id: "confirmation.text.responseText4" },
                  {
                    response: Number(data["responseText4"]) + data["Anzahlung"],
                  }
                )}
              </Col>
            )}
            {star["val2"] && (
              <Col md={12}>
                {star["val2"]}
                {formatMessage(
                  { id: "confirmation.text.responseText2" },
                  { response: Number(data["responseText2"]) + data["Restwert"] }
                )}
              </Col>
            )}
          </Row>
        </div>
      </Col>
    </>
  );
};

const ImageComponent = ({ formValues, isMobile, isMedium }) => {
  return (
    <Col className={`${isMobile ? "p-0 mb-2" : ""}`}>
      <div
        className="car-wrapper"
        style={
          isMobile || isMedium
            ? { height: 180, justifyContent: "center" }
            : { height: 230, justifyContent: "center" }
        }>
        <img
          className={`imageCar`}
          style={{ width: "unset" }}
          alt=""
          src={`https://static-assets.tesla.com/configurator/compositor?&options=${
            formValues["carOptions"] ||
            "$APBS,$DV2W,$IBB0,$PPSW,$PRM30,$SC04,$MDL3,$W40B,$MT314,$CPF0"
          }&view=STUD_3QTR&model=${getCarModel(
            formValues["model"] || "Model S"
          )}&size=1441&bkba_opt=0`}
        />
      </div>
    </Col>
  );
};

const ContractModal = ({ children, ...props }) => {
  const { formatMessage } = useIntl();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="overflow-auto position-static">
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          {formatMessage({ id: "confirmation.button.contractPopupCancel" })}
        </Button>
        <Button disabled={!props.isReadAll} onClick={props.onConfirm}>
          {formatMessage({ id: "confirmation.button.contractPopupRead" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Popup = ({ children, ...props }) => {
  const { formatMessage } = useIntl();
  return (
    <Modal
      {...props}
      keyboard={false}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="">
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>
          {formatMessage({ id: "common.button.close" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function Confirmation({ stepperOptions, onNextStep }) {
  const { getValues, setValue, watch } = useFormContext();
  const { formatMessage } = useIntl();
  const formValues = getValues();
  const data = getValues("contractData");
  const contractID = getValues("contractID");
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  const isMedium = useMediaQuery({
    minWidth: RESPONSIVE.MIN_WIDTH_MEDIUM,
    maxWidth: RESPONSIVE.MAX_WIDTH_MEDIUM,
  });
  const is4K = useMediaQuery({ minWidth: RESPONSIVE.MIN_WIDTH_4K });
  const parentRef = useRef();
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfLink, setPDFLink] = useState();
  const [isLoadSuccess, setLoadSuccess] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const { locale } = useLocale();
  const [isTouched, setTouched] = useState(false);
  const [isShowKYC, setShowKYC] = useState(false);
  const [KYCLink, setKYCLink] = useState("");
  const [zoom, setZoom] = useState(1);
  const [disableConfirmButton, setDisableConfirmButton] = useState(true);

  // Scroll view to top and validate page
  useEffect(() => {
    document
      .getElementsByClassName(
        `${isMobile ? "MuiStepLabel-active" : "active"}`
      )[0]
      .scrollIntoView({ behavior: "smooth", inline: "center" });
    window.scrollTo(0, 0);
    async function validPage() {
      await stepperOptions.triggerForm(5);
    }
    validPage();
  }, []);

  useEffect(() => {
    setDisableConfirmButton(
      isTrue(localStorage.getItem(CONCLUDE_BUTTON_STATUS))
    );
  }, []);

  const _onSubmit = useCallback(async () => {
    if (contractID) {
      try {
        setDisableConfirmButton(true);
        localStorage.setItem(CONCLUDE_BUTTON_STATUS, true);
        const { data, status } = await postBusinessConsent(contractID);
        if (status === 200) {
          let win = window.open(
            "",
            "_blank",
            "width=1200,height=1200,left=50,top=50,location=no,menubar=no,resizable=yes,scrollbars=yes,status=no,titlebar=no,toolbar=no"
          );
          win.document.write(
            `<iframe allowusermedia src="${data["IDnowiFrameURL"]}leaseteqesign/identifications/${data["identID"]}" style="width: 100vw; height: 1200px"  allow="camera *;microphone *" allowfullscreen=""></iframe>`
          );
          // setShowKYC(true);
          // setKYCLink(
          //   `${data["IDnowiFrameURL"]}leaseteqesign/identifications/${data["identID"]}`
          // );
        } else {
          setValue("isSupport", true);
          setValue("status", status);
          onNextStep();
        }
      } catch (error) {
        setDisableConfirmButton(false);
        localStorage.removeItem(CONCLUDE_BUTTON_STATUS);

        setValue("isSupport", true);
        onNextStep();
      }
    }
  }, [onNextStep, contractID]);

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setLoadSuccess(true);
    setNumPages(numPages);
  };

  useEffect(async () => {
    const { data } = await getTerm(locale);
    setPDFLink(data.pdfURL);
  }, [locale]);

  return (
    <>
      <style>{`

        .pdf-center {
          display: flex;
          justify-content: center;
        }

        .pdf-resolution {
          max-height: ${
            window.innerHeight *
            (isMobile || isMedium ? 0.5 : is4K ? 0.9 : 0.75)
          }px !important;
        }
    `}</style>
      <Popup
        size="lg"
        show={isShowKYC}
        onHide={() => {
          setShowKYC(false);
        }}
        backdrop
        animation>
        <iframe
          allowusermedia
          frameborder="0"
          width="100%"
          height="600px"
          src={KYCLink}
          allow="camera *;microphone *"
          allowfullscreen=""></iframe>
      </Popup>
      {isOpenModal && (
        <Document
          loading={""}
          noData={""}
          file={pdfLink}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={() => setOpenModal(false)}>
          <ContractModal
            show={isLoadSuccess}
            onHide={() => {
              setOpenModal(false);
            }}
            onConfirm={() => {
              setValue("checkBox3", true);
              setOpenModal(false);
            }}
            animation={true}
            isReadAll={pageNumber == numPages}>
            <Page
              className={`overflow-auto pdf-resolution ${
                zoom === 1 && "pdf-center"
              }`}
              scale={zoom}
              height={isMobile || isMedium ? window.innerHeight * 0.5 : 1550}
              pageNumber={pageNumber}
            />
            <Row className="pt-3">
              <Col className="d-flex flex-row justify-content-center">
                {(isMobile || isMedium) && (
                  <div
                    className="d-flex w-100"
                    style={{ justifyContent: "flex-start !important" }}>
                    <Button
                      className="mr-2"
                      disabled={zoom === 1}
                      onClick={() => {
                        setZoom(zoom - 1);
                      }}
                      variant="secondary">{`-`}</Button>
                    <Button
                      onClick={() => {
                        setZoom(zoom + 1);
                      }}
                      variant="secondary">{`+`}</Button>
                  </div>
                )}
                {numPages > 1 && (
                  <div className="d-flex ml-2">
                    <Button
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                      variant="secondary">{`<`}</Button>
                    <div className="h3 mx-3">{pageNumber}</div>
                    <Button
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                      variant="secondary">{`>`}</Button>
                  </div>
                )}
              </Col>
            </Row>
          </ContractModal>
        </Document>
      )}
      <Header stepperOptions={stepperOptions} />
      <Container
        className={isMobile ? "pb-2" : "pt-4"}
        style={
          isMobile
            ? {
                backgroundColor: "white",
                overflow: "auto",
                position: "relative",
              }
            : { backgroundColor: "white" }
        }>
        <Row
          className={`verification-title px-4`}
          style={
            isMobile
              ? { position: "relative", fontSize: "18pt" }
              : { position: "relative" }
          }>
          {!isMobile && (
            <>
              <p className="text-left title">
                <Col className="d-flex align-items-center p-0">
                  <div className={`d-flex logo-icon-wrapper mr-2`}>
                    <img className="image" src={LogoIcon} alt="LogoIcon" />
                  </div>
                  <div>{formatMessage({ id: "review.title.pageHeader" })}</div>
                </Col>
              </p>
            </>
          )}
        </Row>
        <Row className={`${isMobile ? "" : "mt-4 p-0 px-4"}`}>
          <Col
            xs={12}
            sm={12}
            md={7}
            lg={7}
            className={`${isMobile ? "p-0" : "pr-65 pl-0"}`}
            ref={parentRef}>
            <ImageComponent
              formValues={formValues}
              isMobile={isMobile}
              isMedium={isMedium}
            />
            <div className={`${isMobile ? "px-4" : ""}`}>
              {isMobile && <Detail data={data} />}

              <div
                className={isMobile ? "mt-2" : "mt-4"}
                style={{ fontSize: "14pt" }}>
                {`${formatMessage({
                  id: `confirmation.text.infoP1`,
                })} ${formatMessage({
                  id: `confirmation.text.infoP2`,
                })}`}
              </div>
              <div className="d-flex my-4 justify-content-center">
                <ButtonComponent
                  style={{ fontSize: "x-large" }}
                  className="layout-button custom-color-input"
                  onClick={_onSubmit}
                  disabled={disableConfirmButton}
                  label={formatMessage({
                    id: "confirmation.button.jetztabschließen",
                  })}
                />
              </div>
            </div>
          </Col>
          {!isMobile && <Detail data={data} />}
        </Row>
      </Container>
    </>
  );
}
