import { FormBlock, FormButton, FormInput } from "components";
import DatepickerComponent from "components/datePicker/datePickerComponent";
import InputRow from "components/InputRow";
import LockIcon from "components/LockIcon";
import { CUSTOM_STYLE, RESPONSIVE } from "constant";
import { format } from "currency-formatter";
import _ from "lodash";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";
import Layout from "../../../components/Layout";
import "./ProfessionIncome.css";

export default function ProfessionIncomeScreen({
  stepperOptions,
  onNextStep,
  onBackStep,
}) {
  const {
    watch,
    formState: { errors, touchedFields },
    getValues,
    control,
    setValue,
  } = useFormContext();
  const { lands = [] } = watch();
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });

  // Validate and go to next page
  const _onNextPage = async () => {
    const isValid = await stepperOptions.triggerForm(3);
    if (isValid) onNextStep();
  };

  // Validate and go to previous page
  const _onBackStep = async () => {
    await stepperOptions.triggerForm(3);
    onBackStep();
  };

  // Scroll view to top
  useEffect(() => {
    document.getElementsByClassName(`${isMobile ? "MuiStepLabel-active" : "active"}`)[0].scrollIntoView({ behavior: "smooth", inline: "center" });
    window.scrollTo(0, 0);
  }, [isMobile]);

  return (
    <>
      <Layout
        stepperOptions={stepperOptions}
        onNextStep={_onNextPage}
        onBackStep={_onBackStep}
        footer={{ icon: <LockIcon /> }}>
        <div className="pt-0" />
        <FormBlock /* Your Employer */
          title={<FormattedMessage id="income.title.yourEmployer" />}>
          {watch("anstellungsverhaeltnis") !== "Ohne Anstellungsverhältnis" && (
            <>
              <Controller /* Employer Name */
                name="arbeitgeber"
                control={control}
                defaultValue={getValues("arbeitgeber")}
                render={({ field }) => {
                  return (
                    <FormInput
                      label={<FormattedMessage id="income.input.company" />}  
                      isValid={
                        (watch("anstellungsverhaeltnis") ===
                        "Ohne Anstellungsverhältnis"
                          ? true
                          : watch("arbeitgeber")) && !errors.arbeitgeber
                      }
                      isShowError={!!errors.arbeitgeber?.message}
                      isRequired
                      {...field}
                    />
                  );
                }}
              />
              <InputRow /* Employer Address */
                label={
                  <>
                    <FormattedMessage id="data.input.addressStreet" />
                    <sup class="required"> *</sup>{" / "}
                    <FormattedMessage id="data.input.addressNumber" />
                    <sup class="required"> *</sup>{" / "}
                    <FormattedMessage id="data.input.addressDoor" />
                  </>
                }
                isValid={
                  (watch("anstellungsverhaeltnis") === "Ohne Anstellungsverhältnis"
                    ? true
                    : watch("strasseArbeitgeber") && watch("hausnummerArbeitgeber")) &&
                  !errors.strasseArbeitgeber &&
                  !errors.hausnummerArbeitgeber
                }
                isShowError={
                  errors.strasseArbeitgeber
                    ? errors.strasseArbeitgeber.message
                    : errors.hausnummerArbeitgeber
                    ? errors.hausnummerArbeitgeber.message
                    : null
                }>
                <Row>
                  <Col md={8} sm={8} xs={8}>
                    <Controller
                      name="strasseArbeitgeber"
                      control={control}
                      defaultValue={getValues("strasseArbeitgeber")}
                      render={({ field }) => {
                        return (
                          <Form.Control
                            className="input-shadow"
                            required
                            {...field}
                            onChange={(event) => {
                              setValue("strasseArbeitgeber", event.target.value);
                            }}
                            onBlur={async () => {
                              field.onBlur();
                            }}
                          />
                        );
                      }}
                    />
                  </Col>
                  <Col md={2} sm={2} xs={2}>
                    <Controller
                      name="hausnummerArbeitgeber"
                      control={control}
                      defaultValue={getValues("hausnummerArbeitgeber")}
                      render={({ field }) => {
                        return (
                          <Form.Control
                            className="input-shadow"
                            required
                            {...field}
                            onChange={(event) => {
                              setValue("hausnummerArbeitgeber", event.target.value);
                            }}
                            onBlur={async () => {
                              field.onBlur();
                            }}
                          />
                        );
                      }}
                    />
                  </Col>
                  <Col md={2} sm={2} xs={2}>
                    <Controller
                      name="doorNumberArbeitgeber"
                      control={control}
                      defaultValue={getValues("doorNumberArbeitgeber")}
                      render={({ field }) => {
                        return (
                          <Form.Control
                            className="input-shadow"
                            {...field}
                            onChange={(event) => {
                              setValue("doorNumberArbeitgeber", event.target.value);
                            }}
                            onBlur={async () => {
                              field.onBlur();
                            }}
                          />
                        );
                      }}
                    />
                  </Col>
                </Row>
              </InputRow>
              <InputRow /* Employer Location */
                label={<FormattedMessage id="income.input.companyPostcode" />}
                isRequired
                isValid={
                  (watch("anstellungsverhaeltnis") === "Ohne Anstellungsverhältnis"
                    ? true
                    : watch("PLZArbeitgeber") && watch("ortArbeitgeber")) &&
                  !errors.PLZArbeitgeber &&
                  !errors.ortArbeitgeber
                }
                isShowError={
                  errors.PLZArbeitgeber
                    ? errors.PLZArbeitgeber.message
                    : errors.ortArbeitgeber
                    ? errors.ortArbeitgeber.message
                    : null
                }>
                <Row>
                  <Col md={4} xs={4} sm={4}>
                    <Controller
                      name="PLZArbeitgeber"
                      control={control}
                      defaultValue={getValues("PLZArbeitgeber")}
                      render={({ field }) => {
                        return (
                          <Form.Control
                            inputMode="numeric"
                            className="input-shadow"
                            required
                            {...field}
                            onChange={(event) => {
                              event.preventDefault();
                              if (!_.isNaN(Number(event.target.value))) {
                                setValue("PLZArbeitgeber", event.target.value);
                              }
                            }}
                          />
                        );
                      }}
                    />
                  </Col>
                  <Col md={8} xs={8} sm={8}>
                    <Controller
                      name="ortArbeitgeber"
                      control={control}
                      defaultValue={getValues("ortArbeitgeber")}
                      render={({ field }) => {
                        return (
                          <Form.Control
                            className="input-shadow"
                            required
                            {...field}
                            onBlur={async () => {
                              field.onBlur();
                            }}
                          />
                        );
                      }}
                    />
                  </Col>
                </Row>
              </InputRow>
              <Controller /* Employer Country */
                name="landArbeitgeber"
                isClearable
                control={control}
                render={({ field }) => (
                  <InputRow
                    isValid={watch("anstellungsverhaeltnis") === "Ohne Anstellungsverhältnis" || field.value}
                    isShowError={errors.landArbeitgeber && errors.landArbeitgeber.message}
                    label={
                      <FormattedMessage
                        id="income.input.companyCountry"
                        /* defaultMessage="Land" */
                      />
                    }
                    isRequired>
                    <Select
                      placeholder={<FormattedMessage id="common.date.year" />}
                      {...field}
                      isSearchable
                      options={lands.map((e) =>
                        e.value === ""
                          ? { value: e.value, label: e.label, isDisabled: true }
                          : e
                      )}
                      styles={CUSTOM_STYLE}
                    />
                  </InputRow>
                )}
              />
            </>
          )}
          <Controller /* Employment Type */
            name="anstellungsverhaeltnis"
            control={control}
            defaultValue={getValues("anstellungsverhaeltnis")}
            render={({ field }) => {
              return (
                <FormButton
                  moreSpace
                  isShowError={!!errors["anstellungsverhaeltnis"]}
                  isRequired
                  isValid={field.value}
                  label={
                    <FormattedMessage
                      id="income.input.employment"
                      /* defaultMessage="Anstellungsverhältnis" */
                    />
                  }
                  buttons={[
                    {
                      icon: "icon-open-end",
                      label: (
                        <FormattedMessage
                          id="income.icon.indefinite"
                          /* defaultMessage="Unbefristet & ungekündigt" */
                        />
                      ),
                      value: "unbefristet & ungekündigt",
                    },
                    {
                      icon: "icon-update",
                      label: (
                        <FormattedMessage
                          id="income.icon.temporary"
                          /* defaultMessage="Temporär" */
                        />
                      ),
                      value: "temporär",
                    },
                    {
                      icon: "icon-unemployed",
                      label: (
                        <FormattedMessage
                          id="income.icon.unemployed"
                          /* defaultMessage="Ohne Anstelle" */
                        />
                      ),
                      value: "Ohne Anstellungsverhältnis",
                    },
                  ]}
                  onClick={(e, value) => {
                    field.onChange(value);
                  }}
                  {...field}
                />
              );
            }}
          />
          {watch("anstellungsverhaeltnis") === "Ohne Anstellungsverhältnis" && (
            <InputRow /* Assets */
              label={formatMessage({id: "income.input.assets",})}
              isValid={watch("vermoegen") && !errors.vermoegen}
              isShowError={errors.vermoegen ? errors.vermoegen.message : null}
              isRequired
            >
              <Row className="d-flex align-items-center">
                <Col md={8}>
                  <Controller
                    name="vermoegen"
                    control={control}
                    defaultValue={""}
                    render={({ field }) => {
                      return (
                        <Form.Control
                          inputMode="numeric"
                          className="input-shadow"
                          required
                          {...field}
                          // onChange={(event) => {
                          //   event.preventDefault();
                          //   if (!_.isNaN(Number(event.target.value))) {
                          //     setValue("vermoegen", event.target.value);
                          //   }
                          // }}
                          onChange={(event) => {
                            event.preventDefault();
                            let valueInput = event.target.value.split(".").join("").trim();
                            if (!_.isNaN(Number(valueInput))) {
                              setValue("vermoegen", valueInput);
                            }
                          }}
                          value={field.value ? format(field.value, {thousand: ".", precision: 0,}) : field.value}
                        />
                      );
                    }}
                  />
                </Col>
                <Col md={4}>
                  <p className="text-secondary align-middle p-0 m-0">
                    <FormattedMessage id="common.text.money" />
                  </p>
                </Col>
              </Row>
            </InputRow>
          )}
          {watch("anstellungsverhaeltnis") !== "Ohne Anstellungsverhältnis" && (
            <Controller /* Employed Since */
              name="angestelltSeit"
              control={control}
              defaultValue={getValues("angestelltSeit")}
              render={({ field: { onChange, onBlur, value }, fieldState }) => {
                return (
                  <InputRow
                    isShowError={!!errors.angestelltSeit}
                    label={formatMessage({
                      id: "income.input.employedSince",
                      /* defaultMessage: "Angestellt seit", */
                    })}
                    isRequired
                    isValid={
                      watch("angestelltSeit") &&
                      fieldState.isTouched &&
                      fieldState.isDirty &&
                      fieldState.error === undefined &&
                      !fieldState.invalid
                    }
                    tooltip={
                      <FormattedMessage
                        id="common.date.format"
                        /* defaultMessage="z.B.15.12.1980" */
                      />
                    }
                  >
                    <Row className="d-flex align-items-center">
                      <Col md={4}>
                        <DatepickerComponent
                          //value={value}
                          value={value ? new Date(value) : value}
                          onChange={(e) => {
                            onChange(e);
                            onBlur(e); //TODO: Trick for validate (mode: "onBlur")
                          }}
                          onBlur={onBlur}
                        />
                      </Col>
                      {/* <Col md={12} className="text-danger"> {errors.angestelltSeit && errors.angestelltSeit.message}</Col> */}
                    </Row>
                  </InputRow>
                );
              }}
            />
          )}
          {watch("anstellungsverhaeltnis") === "temporär" && (
            <Controller /* Employed Until */
              name="angestelltBis"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState }) => {
                return (
                  <>
                    <InputRow
                      label={formatMessage({
                        id: "income.input.limitedUntil",
                        /* defaultMessage: "Befristet bis", */
                      })}
                      isRequired
                      isValid={
                        watch("angestelltBis") &&
                        fieldState.isTouched &&
                        fieldState.isDirty &&
                        fieldState.error === undefined &&
                        !fieldState.invalid
                      }
                      tooltip={
                        <FormattedMessage
                          id="income.date.format.Befristet"
                          /* defaultMessage="z.B.15.12.1980" */
                        />
                      }
                      isShowError={!!errors.angestelltBis}
                    >
                      <div className="row d-flex align-items-center">
                        <Col md={4}>
                          <DatepickerComponent
                            isMinDateNextDay
                            //value={value}
                            value={value ? new Date(value) : value}
                            onChange={(e) => {
                              onChange(e);
                              onBlur(e); //TODO: Trick for validate (mode: "onBlur")
                            }}
                            onBlur={onBlur}
                          />
                        </Col>
                        {/* <Col md={12} className="text-danger"> {errors.angestelltBis && errors.angestelltBis.message}</Col> */}
                      </div>
                    </InputRow>
                  </>
                );
              }}
            />
          )}
        </FormBlock>
        <FormBlock /* Primary Income */
          title={<FormattedMessage id="income.title.primaryIncome" />}>
          <Controller /* Net household income */
            name="monatlichesNettoEinkommen"
            control={control}
            render={({ field }) => {
              return (
                <InputRow
                  label={
                    <FormattedMessage
                      id="income.input.income"
                      /* defaultMessage="Monatliches Nettoeinkommen" */
                    />
                  }
                  tooltip={
                    <FormattedMessage
                      id="income.input.income.tooltip"
                      /* defaultMessage="Monatliches Nettoeinkommen" */
                    />
                  }
                  isShowError={!!errors["monatlichesNettoEinkommen"]}
                  isValid={
                    watch("anstellungsverhaeltnis") ===
                      "Ohne Anstellungsverhältnis" ||
                    (touchedFields["monatlichesNettoEinkommen"] &&
                      !errors["monatlichesNettoEinkommen"])
                  }
                  isRequired
                >
                  <Row className="d-flex align-items-center">
                    <Col md={8}>
                      <Form.Control
                        data-cy="monatlichesNettoEinkommen"
                        inputMode="numeric"
                        className="input-shadow"
                        required
                        {...field}
                        onChange={(event) => {
                          event.preventDefault();
                          let valueInput = event.target.value
                            .split(".")
                            .join("")
                            .trim();
                          // if (valueInput === "" || valueInput === "00" || valueInput === "0") {
                          //   valueInput = 0
                          // }
                          if (!_.isNaN(Number(valueInput))) {
                            setValue("monatlichesNettoEinkommen", valueInput);
                          }
                        }}
                        value={
                          field.value
                            ? format(field.value, {
                                thousand: ".",
                                precision: 0,
                              })
                            : field.value
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <p className="text-secondary align-middle p-0 m-0">
                        <FormattedMessage
                          id="common.text.monthlymoney"
                          /* defaultMessage="CHF" */
                        />
                      </p>
                    </Col>
                  </Row>
                </InputRow>
              );
            }}
          />
          <Row><Col md={4} sm={0}></Col></Row>
        </FormBlock>
        <FormBlock /* Additional Employer */
          title={<FormattedMessage id="income.title.additonalEmployerTitle" />}>
          <Controller
            name="nebenerwerbChecked"
            control={control}
            defaultValue={getValues("nebenerwerbChecked")}
            render={({ field }) => {
              return (
                <FormButton
                  isLeft
                  isShowError={
                    !!errors["nebenerwerb"] || !!errors["nebenerwerbChecked"]
                  }
                  isRequired
                  isValid={
                    watch("anstellungsverhaeltnis") ===
                      "Ohne Anstellungsverhältnis" ||
                    (watch("nebenerwerbChecked") === "Ja"
                      ? watch("nebenerwerb") !== 0 &&
                        touchedFields["nebenerwerb"] &&
                        watch("nebenerwerb") !== ""
                        ? true
                        : false
                      : watch("nebenerwerbChecked") === "Nein"
                      ? true
                      : false)
                  }
                  label={
                    <FormattedMessage
                      id="income.button.additionalEmployer"
                      /* defaultMessage="Haben Sie einen Nebenerwerb / weiterer Arbeitgeber" */
                    />
                  }
                  tooltip={
                    <FormattedMessage
                      id="income.button.additionalEmployer.tooltip"
                      /* defaultMessage="Haben Sie einen Nebenerwerb / weiterer Arbeitgeber" */
                    />
                  }
                  buttons={[
                    {
                      text: (
                        <FormattedMessage
                          id="income.button.yes"
                          /* defaultMessage="Ja" */
                        />
                      ),
                      value: "Ja",
                    },
                    {
                      text: (
                        <FormattedMessage
                          id="income.button.no"
                          /* defaultMessage="Nein" */
                        />
                      ),
                      value: "Nein",
                    },
                  ]}
                  onClick={(e, value) => {
                    field.onChange(value);
                    if (value === "Nein") {
                      setValue("nebenerwerb", "");
                      // clearErrors("nebenerwerb");
                    }
                  }}
                  {...field}
                >
                  {watch("nebenerwerbChecked") === "Ja" && (
                    <Row
                      className={`d-flex align-items-center ml-2 ${
                        isMobile ? "w-55" : "w-80"
                      }`}
                    >
                      <div className="sub-field">
                        <Controller
                          name="nebenerwerb"
                          control={control}
                          defaultValue={watch("nebenerwerb")}
                          render={({ field }) => {
                            return (
                              <Form.Control
                                inputMode="numeric"
                                className="input-shadow"
                                required
                                {...field}
                                onChange={(event) => {
                                  event.preventDefault();
                                  let valueInput = event.target.value
                                    .split(".")
                                    .join("")
                                    .trim();
                                  // if (valueInput === "" || valueInput === "00" || valueInput === "0") {
                                  //   valueInput = 0
                                  // }
                                  if (!_.isNaN(Number(valueInput))) {
                                    setValue("nebenerwerb", valueInput);
                                  }
                                }}
                                value={
                                  field.value
                                    ? format(field.value, {
                                        thousand: ".",
                                        precision: 0,
                                      })
                                    : field.value
                                }
                              />
                            );
                          }}
                        />
                      </div>
                      <Col md={5} className={`${isMobile ? "p-0" : ""}`}>
                        <p className="text-secondary align-middle p-0 m-0">
                          <FormattedMessage
                            id="common.text.monthlymoney"
                            /* defaultMessage="CHF" */
                            values={{ value: "" }}
                          />
                        </p>
                      </Col>
                    </Row>
                  )}
                </FormButton>
              );
            }}
          />
          <Row>
            <Col md={4} sm={0}></Col>
          </Row>
        </FormBlock>
      </Layout>
    </>
  );
}
