import ButtonDirection from "components/CTA/ButtonDirection";
import Input from "components/InputV2/InputV2";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { format } from "currency-formatter";
import { useIntl } from "react-intl";
import { getLink } from "../links-configuration";
import { useLocale } from "context/locale";

const PaymentInformation = ({
  maxResidual,
  price,
  prePayment,
  currResidual,
  onPriceChange,
  onPrePaymentChange,
  onCurrResidualChange,
  paymentViewRef,
  goToDurationView,
  prePaymentMax,
  prePaymentMin,
  showTeslaConfigurator,
}) => {
  const { formatMessage } = useIntl();
  const { locale } = useLocale();

  const formatedPrePaymentMax = format(prePaymentMax, {
    thousand: ".",
    decimal: ",",
    precision: 0,
  });
  const formatedMaxResidual = format(maxResidual, {
    thousand: ".",
    decimal: ",",
    precision: 0,
  });
  const formatedMinResidual = format(price * 0.05, {
    thousand: ".",
    decimal: ",",
    precision: 0,
  });
  return (
    <div className="block-content" ref={paymentViewRef}>
      <Row className="mb-4">
        <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
          <p className="second-title">
            {formatMessage({ id: "publicleasing.title.paymentdetails" })}
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Input
            title={formatMessage({ id: "publicleasing.input.grosslistprice" })}
            subTitle={formatMessage({
              id: "publicleasing.input.currency-desc",
            })}
            desc={formatMessage({
              id: "publicleasing.input.grosslistprice-desc",
            })}
            value={price}
            onChange={onPriceChange}
          />
          {showTeslaConfigurator && (
            <div className="mt-2">
              <a
                href={getLink(locale, "TESTLA_CONFIGURATOR")}
                target="_blank"
                rel="noreferrer"
                className="link-to-tesla">
                {formatMessage({
                  id: "publicleasing.button.go-to-tesla-configurator",
                })}
              </a>
            </div>
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Input
            title={formatMessage({ id: "publicleasing.input.prepayment" })}
            subTitle={formatMessage({
              id: "publicleasing.input.currency-desc",
            })}
            desc={`${formatMessage({
              id: "publicleasing.input.min-desc",
            })} CHF ${prePaymentMin} - ${formatMessage({
              id: "publicleasing.input.max-desc",
            })} CHF ${formatedPrePaymentMax}`}
            value={prePayment}
            onChange={onPrePaymentChange}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Input
            title={formatMessage({ id: "publicleasing.input.residual" })}
            subTitle={formatMessage({
              id: "publicleasing.input.currency-desc",
            })}
            desc={`Minimal CHF ${formatedMinResidual} - ${formatMessage({
              id: "publicleasing.input.max-desc",
            })} CHF ${formatedMaxResidual}`}
            value={currResidual}
            onChange={onCurrResidualChange}
          />
        </Col>
      </Row>
      <Row className="button-direction-wrapper">
        <ButtonDirection direction="down" onClick={() => goToDurationView()} />
      </Row>
    </div>
  );
};

export default PaymentInformation;
