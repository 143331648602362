import React from "react";
import { format } from "currency-formatter";
import { Row, Col } from "react-bootstrap";
import "./cta.css";
import { useIntl } from "react-intl";

function MainInfo({
  model,
  price,
  prePayment,
  currMonth,
  currKm,
  currency,
  currResidual,
}) {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Row className="main-info-item-wrap">
        <Col className="info-key">
          <span>{formatMessage({ id: "publicleasing.text.model" })}</span>
        </Col>
        <Col className="info-value">{model}</Col>
      </Row>
      <Row className="main-info-item-wrap">
        <Col className="info-key">
          {formatMessage({ id: "publicleasing.text.cashprice" })}
        </Col>
        <Col className="info-value">
          {format(price, { 
            thousand: ".", 
            decimal: ",", 
            code: `${currency}`, 
            precision: 2 
          })}
        </Col>
      </Row>
      <Row className="main-info-item-wrap">
        <Col className="info-key">
          {formatMessage({ id: "publicleasing.input.prepayment" })}
        </Col>
        <Col className="info-value">
          {format(prePayment, {
            thousand: ".",
            decimal: ",",
            code: `${currency}`,
            precision: 2,
          })}
        </Col>
      </Row>
      <Row className="main-info-item-wrap">
        <Col className="info-key">
          {formatMessage({ id: "publicleasing.title.term" })}
        </Col>
        <Col className="info-value">{`${currMonth} ${formatMessage({
          id: "publicleasing.button.month",
        })}`}</Col>
      </Row>
      <Row className="main-info-item-wrap">
        <Col className="info-key">
          {formatMessage({ id: "publicleasing.text.annual-mileage" })}
        </Col>
        <Col className="info-value">
          {format(currKm, { thousand: ".", decimal: ",", precision: 0 })} Km
        </Col>
      </Row>
      <Row className="main-info-item-wrap">
        <Col className="info-key">
          {formatMessage({ id: "publicleasing.input.residual" })}
        </Col>
        <Col className="info-value">
          {format(currResidual, {
            thousand: ".",
            decimal: ",",
            code: `${currency}`,
            precision: 2,
          })}
        </Col>
      </Row>
    </div>
  );
}

MainInfo.propTypes = {};

export default MainInfo;
