import React from "react";
import FadeIn from "react-fade-in";
import { FormattedMessage } from "react-intl";
import s from "./loading.module.css";
import svg from "./load.svg";

export default function Loading({ message }) {
  return (
    <div className={s.root}>
      <FadeIn>
        <div className="d-flex justify-content-center align-items-center">
          <img src={svg} height={360} width={380} />
        </div>
        <p className="h4 text-center">
          {message ? (
            message
          ) : (
            <FormattedMessage
              id="common.text.loading"
              /* defaultMessage="Fetching data, please wait" */
            />
          )}
        </p>
      </FadeIn>
    </div>
  );
}
