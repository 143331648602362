import { FormButton } from "components";
import InputRow from "components/InputRow";
import Layout from "components/Layout";
import LockIcon from "components/LockIcon";
import { CUSTOM_STYLE } from "constant";
import { useCountry } from "context/country";
import { useLand } from "context/land";
import { useLeasingFormStep } from "hooks/useLeasingFormStep";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { getPrivateURLPath } from "utils";
import { useLocale } from "../../../context/locale";
import CompanyNameAutoComplete from "../../../screen/Common/CompanyNameAutocomplete/index";
import { getCountry, getCounty } from "../../../utils/api";
import AutoCompleteAddress from "../../Common/AutoCompleteAddress";

// Create range of years
const currentYear = new Date().getFullYear();
const fromYear = currentYear - 100;
const years = [];
for (let i = currentYear; i >= fromYear; i -= 1) {
  years.push({ value: i, label: i });
}

const AskPrivatePopup = ({ children, ...props }) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="overflow-auto">
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onConfirm}>
          <FormattedMessage id="company.button.yes" />
        </Button>
        <Button variant="secondary" onClick={props.onHide}>
          <FormattedMessage id="company.button.no" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const COUNTRY_LIST = {
  de: [
    { value: "AT", label: "Österreich" },
    // { value: "CH", label: "Schweiz" },
    // { value: "LI", label: "Liechtenstein" },
  ],
  en: [
    { value: "AT", label: "Austria" },
    // { value: "CH", label: "Switzerland" },
    // { value: "LI", label: "Liechtenstein" },
  ],
  it: [
    { value: "AT", label: "Austria" },
    // { value: "CH", label: "Svizzera" },
    // { value: "LI", label: "Liechtenstein" },
  ],
  fr: [
    { value: "AT", label: "Autriche" },
    // { value: "CH", label: "Suisse" },
    // { value: "LI", label: "Liechtenstein" },
  ],
};

export default function Company({ stepperOptions, onNextStep, onBackStep }) {
  const { formatMessage } = useIntl();
  const {
    watch,
    formState: { errors, touchedFields },
    getValues,
    control,
    setValue,
    trigger,
  } = useFormContext();
  const { lands = [] } = watch();
  const [isOpenAskPrivatePopup, setOpenAskPrivatePopup] = useState(false);
  const location = useLocation();
  const { updateCurrentStep } = useLeasingFormStep();
  const { locale } = useLocale();
  const { countryContext } = useCountry();
  const { land } = useLand();
  const { value } = getValues("land");
  const [countyOptions, setCountyOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [deductionAmount, setDeductionAmount] = useState("");

  const handleCountry = async () => {
    // the function is called when the country or the language is changed to call counties of the selected country
    const {
      data: { countryList },
    } = await getCountry();

    let tempCountyArr = [];

    // reorderCountysBySequence(countryList);

    if (countryList) {
      for (let country of countryList) {
        // eslint-disable-next-line default-case
        switch (locale) {
          case "de":
            tempCountyArr.push({
              value: country.isoCode,
              label: country.de,
            });
            break;
          case "fr":
            tempCountyArr.push({
              value: country.isoCode,
              label: country.fr,
            });
            break;
          case "it":
            tempCountyArr.push({
              value: country.isoCode,
              label: country.it,
            });
            break;
          case "en":
            tempCountyArr.push({
              value: country.isoCode,
              label: country.en,
            });
            break;
        }
      }
      setCountryOptions(tempCountyArr);
    }
  };

  // const handleCounty = async () => {
  //   // the function is called when the country or the language is changed to call counties of the selected country
  //   const {
  //     data: { countyList },
  //   } = await getCounty(value);

  //   let tempCountyArr = [];

  //   reorderCountysBySequence(countyList);

  //   for (let county of countyList) {
  //     // eslint-disable-next-line default-case
  //     switch (locale) {
  //       case "de":
  //         tempCountyArr.push({
  //           value: county.ISOcountyCode,
  //           deductionAmount: county.deductionAmount,
  //           label: county.de_county,
  //         });
  //         break;
  //       case "fr":
  //         tempCountyArr.push({
  //           deductionAmount: county.deductionAmount,
  //           value: county.ISOcountyCode,
  //           label: county.fr_county,
  //         });
  //         break;
  //       case "it":
  //         tempCountyArr.push({
  //           deductionAmount: county.deductionAmount,
  //           value: county.ISOcountyCode,
  //           label: county.it_county,
  //         });
  //         break;
  //       case "en":
  //         tempCountyArr.push({
  //           value: county.ISOcountyCode,
  //           deductionAmount: county.deductionAmount,
  //           label: county.en_county,
  //         });
  //         break;
  //     }
  //   }
  //   setCountyOptions(tempCountyArr);
  //   console.log(tempCountyArr);
  // };

  // const reorderCountysBySequence = (array) => {
  //   array.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
  // };
  /*
    const getCountyList = async (countryCode) => {
      console.log(countryCode);
      const token = "8vbq9cTKsRCapuKEmwWfqR6TlDzZOKQbOmoUqEHy";
      const res = await axios.get(
        "https://leasing.dev2.saas.do/api/v1/countyList",
        {
          params: { ISOcountryCode: countryCode },
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
      ).then((res) => {
        console.log(res.data);
        
     
        fillCountyList(res.data);
    
      }).catch((error) => {
        console.error(error)
      });
    }
    const dummyObject = {
      value: "", 
      label: ""
    }
    
    const fillCountyList = (countyList) => {
      for(let county of countyList){
        dummyObject.label=county.ISOcountyCode;
        dummyObject.value=county.
      }
    }
    
    const COUNTRY_LIST = {
      de: [
        
      ],
      en: [
       
      ],
      it: [
       
      ],
      fr: [
        { value: "CH", label: "Suisse" },
        {
          label: "Liechtenstein",
          value: "LI",
        },
      ],
    }*/

  useEffect(() => {
    handleCountry();
    // handleCounty();
  }, [locale, value]);

  // recalculate the total price when county is selected
  // useEffect(() => {
  //   setDeductionAmount(getValues("county").deductionAmount);
  // }, [getValues("county")]);

  // Validate and go to next page
  const _onNextStep = async () => {
    const isValid = await stepperOptions.triggerForm(2);
    if (isValid) {
      onNextStep();
    }
  };

  // Validate and go to previous page
  const _onBackStep = async () => {
    await stepperOptions.triggerForm(2);
    onBackStep();
  };

  // Update country language when locale is changed
  useEffect(() => {
    const countries = (countryContext?.returnCountry || []).map((item) => ({
      value: item.isoCode,
      label: item[locale],
    }));
    const lands = (land?.returnCountry || []).map((item) => ({
      value: item.isoCode,
      label: item[locale],
    }));
    const defaultCountryISOCode = "CH";
    const defaultCountry =
      countries.find((item) => item.value === defaultCountryISOCode) || {};
    const translatedCountry = (name) =>
      countries.find((item) => item.value === getValues(name).value) || {};
    const defaultLand =
      lands.find((item) => item.value === getValues("land").value) || {};
    setValue("defaultCountry", defaultCountry);
    setValue("defaultLand", defaultLand);
    setValue("countries", countries);
    setValue("lands", lands);
    setValue("land", defaultLand);
    setValue("companyCountry", translatedCountry("companyCountry"));
  }, [locale]);

  return (
    <>
      <AskPrivatePopup
        show={isOpenAskPrivatePopup}
        onHide={() => {
          setOpenAskPrivatePopup(false);
        }}
        onConfirm={() => {
          const privatePath = getPrivateURLPath(location);
          const privateURL =
            window.location.href.split("?")[0] + "?" + privatePath;
          updateCurrentStep(1);
          window.location.replace(privateURL);
          setOpenAskPrivatePopup(false);
        }}
        animation={true}>
        <FormattedMessage id="company.input.askPrivate" />
      </AskPrivatePopup>
      <Layout
        title={formatMessage({ id: "company.title.pageHeader" })}
        stepperOptions={stepperOptions}
        onNextStep={_onNextStep}
        onBackStep={_onBackStep}
        footer={{ icon: <LockIcon /> }}>

        {/* Company name */}
        <Controller
          name="companyName"
          control={control}
          defaultValue={getValues("companyName")}
          render={({ field }) => {
            return (
              <InputRow
                isValid={watch("companyName") && !errors.companyName}
                isShowError={!!errors.companyName?.message}
                label={<FormattedMessage id="company.input.companyName" />}
                tooltip={<FormattedMessage id="company.input.companyName.tooltip" />}
                isRequired>
                <Form.Control required {...field} />
              </InputRow>
            );
          }}
        />

        {/* Street, No. and door */}
        <InputRow
          label={
            <>
              {/* <FormattedMessage
                id="company.input.street"
              /> */}
              <FormattedMessage
                id="data.input.addressStreet"
              />
              <sup class="required"> *</sup>{" / "}
              <FormattedMessage
                id="data.input.addressNumber"
              />
              <sup class="required"> *</sup>{" / "}
              <FormattedMessage
                id="data.input.addressDoor"
              />
            </>
          }
          isValid={
            watch("companyCountry").value !== "AT" ||
            (watch("companyStreet") &&
              watch("companyNo") &&
              !errors.companyStreet &&
              !errors.companyNo)
          }
          isShowError={
            watch("companyCountry").value === "AT" &&
            (errors.companyStreet
              ? errors.companyStreet.message
              : errors.companyNo
              ? errors.companyNo.message
              : null)
          }>
          <Row>
            <Col md={8} sm={8} xs={8}>
              <Controller
                  name="companyStreet"
                  control={control}
                  defaultValue={getValues("companyStreet")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        className="input-shadow"
                        required
                        {...field}
                        onChange={(event) => {
                          setValue("companyStreet", event.target.value);
                        }}
                        onBlur={async () => {
                          field.onBlur();
                        }}
                      />
                    );
                  }}
                />
            </Col>
            <Col md={2} sm={2} xs={2}>
              <Controller
                name="companyNo"
                control={control}
                defaultValue={getValues("companyNo")}
                render={({ field }) => {
                  return (
                    <Form.Control
                      className="input-shadow"
                      required
                      {...field}
                      onChange={(event) => {
                        setValue("companyNo", event.target.value);
                      }}
                      onBlur={async () => {
                        field.onBlur();
                      }}
                    />
                  );
                }}
              />
            </Col>
            <Col md={2} sm={2} xs={2}>
              <Controller
                name="companyDoorNumber"
                control={control}
                defaultValue={getValues("companyDoorNumber")}
                render={({ field }) => {
                  return (
                    <Form.Control
                      className="input-shadow"
                      {...field}
                      onChange={(event) => {
                        setValue("companyDoorNumber", event.target.value);
                      }}
                      onBlur={async () => {
                        field.onBlur();
                      }}
                    />
                  );
                }}
              />
            </Col>
          </Row>
        </InputRow>
        
        {/* Zip code and town */}
        <InputRow
          label={<FormattedMessage id="company.input.plz" />}
          isRequired
          isValid={
            watch("companyZIP") &&
            watch("companyPlace") &&
            !errors.companyZIP &&
            !errors.companyPlace
          }
          isShowError={
            errors.companyZIP
              ? errors.companyZIP.message
              : errors.companyPlace
              ? errors.companyPlace.message
              : null
          }>
          <Row>
            <Col md={4} xs={4} sm={4}>
              <Controller
                name="companyZIP"
                control={control}
                defaultValue={getValues("companyZIP")}
                render={({ field }) => {
                  return (
                    <Form.Control
                      inputMode="numeric"
                      className="input-shadow"
                      required
                      {...field}
                      onChange={(event) => {
                        event.preventDefault();
                        if (!_.isNaN(Number(event.target.value))) {
                          setValue("companyZIP", event.target.value);
                        }
                      }}
                      onBlur={async () => {
                        field.onBlur();
                      }}
                    />
                  );
                }}
              />
            </Col>
            <Col md={8} xs={8} sm={8}>
              <Controller
                name="companyPlace"
                control={control}
                defaultValue={getValues("companyPlace")}
                render={({ field }) => {
                  return (
                    <Form.Control
                      className="input-shadow"
                      required
                      {...field}
                      onBlur={async () => {
                        field.onBlur();
                      }}
                    />
                  );
                }}
              />
            </Col>
          </Row>
        </InputRow>
        <Controller
          name="companyCountry"
          isClearable
          control={control}
          render={({ field }) => {
            return (
              <InputRow
                isShowError={
                  errors.companyCountry && errors.companyCountry.message
                }
                isValid={field.value}
                label={<FormattedMessage id="company.input.country" />}
                isRequired>
                <Select
                  placeholder={<FormattedMessage id="common.date.year" />}
                  {...field}
                  isSearchable
                  options={COUNTRY_LIST[String(locale).toLowerCase()].map((e) =>
                    e.value === ""
                      ? { value: e.value, label: e.label, isDisabled: true }
                      : e
                  )}
                  styles={CUSTOM_STYLE}
                />
              </InputRow>
            );
          }}
        />

        {/* <Controller
          name="companyCounty"
          isClearable
          control={control}
          render={({ field }) => (
            <InputRow
              isValid={field.value}
              label={<FormattedMessage id="data.input.county" />}
              isRequired>
              <Select
                placeholder={<FormattedMessage id="common.date.year" />}
                isSearchable
                options={countyOptions}
                styles={CUSTOM_STYLE}
                {...field}
              />
            </InputRow>
          )}
        /> */}

        <Controller
          name="companyFounded"
          isClearable
          control={control}
          render={({ field }) => (
            <InputRow
              isShowError={!!errors.companyFounded}
              isValid={field.value && !errors.companyFounded}
              label={<FormattedMessage id="company.input.companyFounded" />}
              tooltip={
                <FormattedMessage id="company.input.companyFounded.tooltip" />
              }
              isRequired>
              <div className="w-180">
                <Select
                  placeholder={<FormattedMessage id="common.date.year" />}
                  isSearchable
                  options={years}
                  styles={CUSTOM_STYLE}
                  {...field}
                />
              </div>
            </InputRow>
          )}
        />

        <Controller
          name="companyUID"
          control={control}
          defaultValue={getValues("companyUID")}
          render={({ field }) => {
            return (
              <InputRow
                isValid={watch("companyUID") && !errors.companyUID}
                isShowError={!!errors.companyUID?.message}
                label={<FormattedMessage id="company.input.companyUID" />}
                tooltip={
                  <FormattedMessage id="company.input.companyUID.tooltip" />
                }
                isRequired>
                <Form.Control required {...field} />
              </InputRow>
            );
          }}
        />

        <Controller
          name="companyEmployees"
          control={control}
          defaultValue={getValues("companyEmployees")}
          render={({ field }) => {
            return (
              <InputRow
                isValid={watch("companyEmployees") && !errors.companyEmployees}
                isShowError={!!errors.companyEmployees?.message}
                label={<FormattedMessage id="company.input.companyEmployees" />}
                tooltip={
                  <FormattedMessage id="company.input.companyEmployees.tooltip" />
                }
                isRequired>
                <Form.Control
                  inputMode="numeric"
                  required
                  {...field}
                  onChange={(event) => {
                    event.preventDefault();
                    if (!_.isNaN(Number(event.target.value))) {
                      setValue("companyEmployees", event.target.value);
                    }
                  }}
                  onBlur={(event) => {
                    event.preventDefault();
                    if (Number(field.value) === 0) {
                      /**
                       * Modal is feature-toggled and disabled
                       * Modal can be easily activated, as soon as the B2C journey went live
                       */
                      // setOpenAskPrivatePopup(true);
                    }
                    trigger("companyEmployees");
                  }}
                />
              </InputRow>
            );
          }}
        />

        <Controller
          name="betreibungen"
          control={control}
          defaultValue={getValues("betreibungen")}
          render={({ field }) => {
            return (
              <FormButton
                isLeft
                isRequired
                isValid={field.value}
                isShowError={errors.betreibungen && errors.betreibungen.message}
                label={<FormattedMessage id="company.text.enforcements" />}
                buttons={[
                  {
                    text: <FormattedMessage id="company.button.yes" />,
                    value: "Ja",
                  },
                  {
                    text: <FormattedMessage id="company.button.no" />,
                    value: "Nein",
                  },
                ]}
                onClick={(e, value) => {
                  field.onChange(value);
                }}
                {...field}
              />
            );
          }}
        />
      </Layout>
    </>
  );
}
