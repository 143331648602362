import { FormattedMessage } from "react-intl";

// Style
const CUSTOM_STYLE = {
  control: (base) => ({
    ...base,
    height: "3.5em",
    minHeight: "3.5em",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#AFCA0B"
        : isFocused
        ? "#79A254"
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? "white"
        : isFocused
        ? "white"
        : "#3e3e3e",
      ":active": {
        ...styles[":active"],
        backgroundColor: "#79A254",
      },
    };
  },
};

// Responsive
const RESPONSIVE = {
  MAX_WIDTH_SMALL: 767,
  MIN_WIDTH_MEDIUM: 768,
  MAX_WIDTH_MEDIUM: 991,
  MIN_WIDTH_LARGE: 992,
  MIN_WIDTH_4K: 3840,
};

// Data
const LEASING_STATE_KEY = "leasingFormState";
const LEASING_OPTION_KEY = "leasingFormOptions";
const LEASING_STEP_KEY = "leasingFormStep";
const CONCLUDE_BUTTON_STATUS = "CONCLUDE_BUTTON_STATUS";

const DEFAULT_COUNTRY = {
  value: "de_AT",
  label: "Germany",
};

const COUNTRIES = [
  DEFAULT_COUNTRY,
  {
    value: "en_AT",
    label: "English",
  },
  // {
  //   value: "it_AT",
  //   label: "Italia",
  // },
  // {
  //   value: "fr_AT",
  //   label: "French",
  // },
];

const MODEL_OPTIONS = {
  model3: "m3",
  models: "ms",
  modelx: "mx",
  modely: "my",
};

// Route
const PRIVATE_STEPPER_OPTIONS = {
  numberOfSteps: 6,
  items: [
    {
      title: (
        <FormattedMessage
          id="route.title.leasingDetails"
          /* defaultMessage="Leasinginformationen" */
        />
      ),
      step: 1,
    },
    {
      title: (
        <FormattedMessage
          id="route.title.data"
          /* defaultMessage="Persönliche Angaben" */
        />
      ),
      step: 2,
    },
    {
      title: (
        <FormattedMessage
          id="route.title.income"
          /* defaultMessage="Beruf und Einnahmen" */
        />
      ),
      step: 3,
    },
    {
      title: (
        <FormattedMessage
          id="route.title.expenses"
          /* defaultMessage="Wohnen & Ausgaben" */
        />
      ),
      step: 4,
    },
    {
      title: (
        <FormattedMessage
          id="route.title.review"
          /* defaultMessage="Überprüfung und Abschluss" */
        />
      ),
      step: 5,
    },
    {
      title: (
        <FormattedMessage
          id="route.title.transmission"
          /* defaultMessage="Sendebestätigung" */
        />
      ),
      step: 6,
    },
  ],
};

const BUSINESS_STEPPER_OPTIONS = {
  numberOfSteps: 6,
  items: [
    {
      title: <FormattedMessage id="routeB.title.calculator" />,
      step: 1,
    },
    {
      title: <FormattedMessage id="routeB.title.company" />,
      step: 2,
    },
    {
      title: <FormattedMessage id="routeB.title.contact" />,
      step: 3,
    },
    {
      title: <FormattedMessage id="routeB.title.financials" />,
      step: 4,
    },
    {
      title: <FormattedMessage id="routeB.title.review" />,
      step: 5,
    },
    {
      title: <FormattedMessage id="routeB.title.transmissionConf" />,
      step: 6,
    },
  ],
};

const WEBSITE_URL = "https://www.leaseteq.ch";

const LANGUAGE_OPTIONS = [
  {
    value: "de",
    label: "DE",
  },
  // {
  //   value: "fr",
  //   label: "FR",
  // },
  // {
  //   value: "it",
  //   label: "IT",
  // },
  {
    value: "en",
    label: "EN",
  },
];

const LANGUAGE_OPTIONS_PUBLICLEASING = [
  {
    value: "de",
    label: "Deutsch",
  },
  // {
  //   value: "fr",
  //   label: "Français",
  // },
  // {
  //   value: "it",
  //   label: "Italiano",
  // },
  {
    value: "en",
    label: "English",
  },
];

export {
  RESPONSIVE,
  DEFAULT_COUNTRY,
  COUNTRIES,
  PRIVATE_STEPPER_OPTIONS,
  BUSINESS_STEPPER_OPTIONS,
  LEASING_STATE_KEY,
  LEASING_STEP_KEY,
  MODEL_OPTIONS,
  WEBSITE_URL,
  CUSTOM_STYLE,
  LANGUAGE_OPTIONS,
  CONCLUDE_BUTTON_STATUS,
  LEASING_OPTION_KEY,
  LANGUAGE_OPTIONS_PUBLICLEASING,
};
