import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useLocale } from "context/locale";
import qs from "query-string";
import "./LanguageSwicherDropdown.css";
import { LANGUAGE_OPTIONS_PUBLICLEASING } from "constant";
import { Dropdown, Image, Modal } from "react-bootstrap";
import IconBurger from "../../assets/icon-burger.svg";
import CloseIcon from "../../assets/close-icon.png";
import Logo from "../../assets/logo.png";
import Linked from "../../assets/linked-black.png";
import { getLink } from "screen/LeasingFormPublic/links-configuration";
import { useIntl } from "react-intl";

export default function LanguageSwicherDropdown({ isResponsive }) {
  const { setLocale, locale } = useLocale();
  const [show, setShow] = useState(false);

  const { formatMessage } = useIntl();
  const location = useLocation();
  const history = useHistory();

  const localMapper = {
    en: "English",
    // fr: "Français",
    // it: "Italiano",
    de: "Deutsch",
  };

  const handleChange = (element) => {
    document.body.style.overflow = "";
    setLocale(element.value.toLowerCase());
    const queryParams = qs.parse(location.search);
    delete queryParams.country;
    const newQueries = {
      ...queryParams,
      country: `${element.value.toLowerCase()}_AT`,
    };
    history.push({ search: qs.stringify(newQueries) });
  };

  const modalView = (
    <div>
      <Image
        src={IconBurger}
        className="icon-burger"
        fluid
        onClick={() => {
          setShow(true);
          document.body.style.overflow = "hidden";
        }}
      />
      {show && (
        <div className="modal-mobile">
          <div className="modal-dialog-custom-mobile">
            <div className="main-body-content-wrapper">
              <div className="close-icon-wrapper">
                <Dropdown className="dropdown-sticky-menu">
                  <Dropdown.Toggle>
                    <Image
                      src={CloseIcon}
                      className="close-icon"
                      fluid
                      onClick={() => {
                        setShow(false);
                        document.body.style.overflow = "";
                      }}
                    />
                  </Dropdown.Toggle>
                </Dropdown>
              </div>
              <div className="logo-and-link-wrapper">
                <div className="item-logo-modal">
                  <Image src={Logo} className="logo" fluid />
                </div>
                <div className="item-logo-modal-homepage">
                  <a href={getLink(locale, "HOME_PAGE")}>
                    <span className="font-weight-bold mb-0">
                      {formatMessage({ id: "publicleasing.text.homepage" })}
                    </span>
                  </a>
                </div>
                <div className="item-logo-modal-about-us">
                  <a href={getLink(locale, "ABOUT_US")}>
                    <span className="font-weight-bold mb-0">
                      {formatMessage({ id: "publicleasing.text.about-us" })}
                    </span>
                  </a>
                </div>
                <div className="item-logo-modal-about-us">
                  <a href="https://app.leaseteq.ch/#/leasingcalculator">
                    <span className="font-weight-bold mb-0 active">
                      {formatMessage({
                        id: "publicleasing.text.leasingcalculator",
                      })}
                    </span>
                  </a>
                </div>
              </div>
              <div className="languague-wrapper">
                <div className="dropdown-modal-wrapper">
                  <Dropdown className="dropdown-modal-menu">
                    <Dropdown.Toggle className="font-weight-bold language-modal-menu">
                      {localMapper[locale]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {LANGUAGE_OPTIONS_PUBLICLEASING.map((element) => {
                        return (
                          <Dropdown.Item
                            className="dropdown-item-modal"
                            onClick={() => handleChange(element)}
                            key={element.label}>
                            {element.label}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <a
                  href="https://www.linkedin.com/company/leaseteq-ag/"
                  target="blank"
                  className="linked-modal-wrapper">
                  <Image src={Linked} className="linked-icon" fluid />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  const dropdownView = (
    <Dropdown className="dropdown-sticky-menu">
      <Dropdown.Toggle id="dropdown-basic" className="font-weight-bold">
        {localMapper[locale]}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-item-modal">
        {LANGUAGE_OPTIONS_PUBLICLEASING.map((element) => {
          return (
            <Dropdown.Item
              onClick={() => handleChange(element)}
              key={element.label}>
              {element.label}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
  return isResponsive ? modalView : dropdownView;
}
