import React, { Fragment } from "react";
import { Image } from "react-bootstrap";
import "./HeaderV2.css";
import Logo from "../../assets/logo.png";
import { useMediaQuery } from "react-responsive";
import { RESPONSIVE } from "constant";
import LanguageSwicherDropdown from "components/LanguageSwicherDropdown";
import { useIntl } from "react-intl";
import { getLink } from "screen/LeasingFormPublic/links-configuration";
import { useLocale } from "context/locale";

function Header(props) {
  const isSmallSize = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  const { locale } = useLocale();

  const { formatMessage } = useIntl();
  return (
    <Fragment>
      {isSmallSize ? (
        <div className="mobil-header">
          <div>
            <Image src={Logo} className="logo" />
          </div>
          <div>
            <div className="header-right-item-wrapper">
              <LanguageSwicherDropdown isResponsive={true} />
            </div>
          </div>
        </div>
      ) : (
        <div className="sticky-header">
          <div className="sticky-header-first-wrap">
            <div className="logo-wrapper-v2">
              <Image src={Logo} alt="logo-header" className="logo-header" />
            </div>
            <div className="link-wrapper">
              <a href={getLink(locale, "HOME_PAGE")}>
                <span className="font-weight-bold mb-0 menu-link">
                  {formatMessage({ id: "publicleasing.text.homepage" })}
                </span>
              </a>
              <a
                href={getLink(locale, "ABOUT_US")}
                style={{ paddingLeft: "2px" }}>
                <span className="font-weight-bold mb-0 menu-link">
                  {formatMessage({ id: "publicleasing.text.about-us" })}
                </span>
              </a>

              <a href="https://app.leaseteq.ch/#/leasingcalculator">
                <span className="font-weight-bold mb-0 menu-link active">
                  {formatMessage({
                    id: "publicleasing.text.leasingcalculator",
                  })}
                </span>
              </a>
            </div>
          </div>
          <div className="sticky-header-second-wrap dropdown-wrapper">
            <LanguageSwicherDropdown />
          </div>
        </div>
      )}
    </Fragment>
  );
}

Header.propTypes = {};

export default Header;
