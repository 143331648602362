import queryString from "query-string";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import "./style.css";

const NotFound = () => {
  const { search } = useLocation();
  const values = queryString.parse(search);

  const [status, setStatus] = useState("");

  useEffect(() => {
    if (values.status !== undefined) {
      setStatus(values.status);
    }
  }, [values]);
  return (
    <>
      <div id="notfound">
        <div className="notfound">
          {status.length > 0 ? <h2>Status Code: {status}</h2> : <></>}
          <div className="notfound-404">
            <h1>
              <FormattedMessage id="notfound.text.oops" />
            </h1>
            <h2>
              <FormattedMessage id="notfound.text.404" />
            </h2>
            <h2>
              <FormattedMessage id="notfound.text.404b" />
            </h2>
          </div>
          <a href={localStorage.getItem("currentStepUrl")}>
            <FormattedMessage id="notfound.text.gohome" />
          </a>
        </div>
      </div>
    </>
  );
};

export default NotFound;
