import React, { useState, useEffect, useRef } from "react";
import "./inputV2.css";
import { format, unformat } from "currency-formatter";
import PencilSvg from "../../assets/icon-edit.svg";

function Input({
  title,
  subTitle,
  value,
  onChange,
  desc,
  additionalInfo,
  placeholder,
}) {
  const [localValue, setLocalValue] = useState(value);
  const [isTyping, setIsTyping] = useState(false);
  const inputRef = useRef(null);
  const [onEdit, setOnEdit] = useState(false);
  const [width, setWidth] = useState(4);

  useEffect(() => {
    if (onEdit) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [onEdit]);

  useEffect(() => {
    const formatedValue = format(value, { thousand: ".", decimal: ",", precision: 0 });
    setLocalValue(formatedValue);
    setWidth(formatedValue.length < 4 ? 4.5 : formatedValue.length + 0.5);
  }, [value, onChange]);

  return (
    <div>
      <div className="inputV2-wrapper">
        <input
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          ref={inputRef}
          placeholder={placeholder}
          onChange={(event) => setLocalValue(event.target.value)}
          onBlur={() => {
            setOnEdit(false);
            setIsTyping(false);
            onChange(unformat(localValue, { thousand: ".", decimal: ",", precision: 0 }));
          }}
          onClick={(e) => {
            setOnEdit(!onEdit);
            setIsTyping(true);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              setOnEdit(false);
              setIsTyping(false);
              onChange(unformat(localValue, { thousand: ".", decimal: ",", precision: 0 }));
            }
          }}
          className={`input-v2 focus ${
            isTyping ? "clicked bg-image-none" : ""
          }`}
          value={localValue}
          style={{
            width: width + "ch",
            background: `url(${PencilSvg})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right center",
            backgroundSize: "20px",
          }}
        />
      </div>
      <h6 className="title-v2">
        {title}
        <span className="sub-title-v2"> ({subTitle})</span>
      </h6>
      <div className="desc-v2">{desc}</div>
      {additionalInfo && <div className="desc-v2">{additionalInfo}</div>}
    </div>
  );
}

Input.propTypes = {};

export default Input;
