import { colors } from "themes/colors";

const TEXT_ITEM_HEIGHT = 45;
const BREADCRUMB_ITEM_HEIGHT = 56;
export const ARROW_WIDTH = 24;

export const BreadcrumbItem = ({ index, label, isActive, onClick }) => {
  return (
    <>
      <style>{`
        .breadcrumb-item {
          list-style-type: none;
          position: relative;
        }
        .breadcrumb-item.active {
          background: ${colors.breadcrumbActive};
          border-left-color: ${colors.breadcrumbActive};
        }
        .breadcrumb-item.in-active {
          background: ${colors.breadcrumbInActive};
          border-color: ${colors.breadcrumbInActive};
        }
        .breadcrumb-item::before {
          content: "";  
          border-top: ${BREADCRUMB_ITEM_HEIGHT / 2 + 1}px solid transparent;
          border-bottom: ${BREADCRUMB_ITEM_HEIGHT / 2 + 1}px solid transparent;
          border-left: ${ARROW_WIDTH}px solid;
          border-left-color: ${colors.white};
          position: absolute; 
          left: -1px; 
          top: -1px;
          z-index: 1;
        }
        .breadcrumb-item::after {
          content: "";
          border-top: ${BREADCRUMB_ITEM_HEIGHT / 2 + 1}px solid transparent;
          border-bottom: ${BREADCRUMB_ITEM_HEIGHT / 2 + 1}px solid transparent;
          border-left: ${ARROW_WIDTH}px solid;
          border-left-color: inherit;
          position: absolute; 
          right: -${ARROW_WIDTH - 1}px; 
          top: -1px;
          z-index: 2;
          height: 100%;
          overflow: hidden;
        }

        .breadcrumb-item > span {
          text-decoration: none;
          padding: 18px 9px;
          position: relative;
          display: flex;
          max-height: ${TEXT_ITEM_HEIGHT}px;
          over-flow: hidden;

          justify-content: center;
          align-items: center;
          height: 56px;
          cursor: default;
          font-weight: 400;
          font-style: normal;
          font-size: 14px;
          line-height: 22.4px;
          text-align: center;
          padding-left: 27px;
        }
        .breadcrumb-item > span.active {
          border-color:  ${colors.breadcrumbActive};
          border-left-color: ${colors.breadcrumbActive};
          color: ${colors.gray2};
        }
        .breadcrumb-item > span.in-active {
          border-color: ${colors.breadcrumbInActive};
          color: ${colors.gray2};
        }

        .breadcrumb-item > span p {
          text-align: center;
          width: 22px;
          height: 22px;
          padding: 0;
          margin: 0;
          margin-right: 8px;
          line-height: 22px;
          color: ${colors.gray2};
          font-size: 28px;
          font-weight: 500;
        }
        .breadcrumb-item > span > span {
          flex: 1;
          line-height: ${TEXT_ITEM_HEIGHT / 2}px;
          max-height: ${TEXT_ITEM_HEIGHT}px;
          overflow: hidden;
          text-align: left;
        }
        .breadcrumb-item > span.in-active p {
          border-color: ${colors.gray3};
        }
        .breadcrumb-item > span.active p {
          border-color: ${colors.primary};
        }

        .breadcrumb-item.first::before {
          content: unset
        }
      `}</style>

      <li
        className={`breadcrumb-item ${isActive ? "active" : "in-active"}  ${
          index === 1 ? "first" : ""
        }`}
      >
        <span
          href="#"
          className={`${isActive ? "active" : "in-active"}`}
          onClick={onClick}
        >
          {index && <p>{index}</p>}
          <span>{label}</span>
        </span>
      </li>
    </>
  );
};
