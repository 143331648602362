import React from "react";
import LeftBox from "./LeftBox";
import PageHeader from "./PageHeader";
import RightBox from "./RightBox";
import { RESPONSIVE } from "constant";
import { useMediaQuery } from "react-responsive";

export default function BoxContent({ marke }) {
  const isLargeSize = useMediaQuery({ minWidth: RESPONSIVE.MIN_WIDTH_LARGE });
  
  return (
    <>
      {!isLargeSize && <LeftBox />}
      <div
        className={`d-flex flex-column ${
          isLargeSize ? "p-0 py-4" : "px-4 py-3"
        }`}
        style={{ backgroundColor: "#fff", color: "#1C1F26" }}
      >
        <PageHeader />
        {isLargeSize ? (
          <div className="d-flex flex-row">
            <LeftBox />
            <RightBox marke={marke} />
          </div>
        ) : (
          <RightBox marke={marke} />
        )}
      </div>
    </>
  );
}
