import React from "react";
import { Col } from "react-bootstrap";
import { getCarModel } from "utils";

export default function CarImage({ data, isMobile, isMediumSize }) {
  return (
    <Col className={`${isMobile ? "p-0" : ""}`}>
      <div
        className="car-wrapper"
        style={
          isMobile
            ? { height: 180, justifyContent: "center" }
            : isMediumSize
            ? { height: 350, justifyContent: "center" }
            : { height: 230, justifyContent: "center" }
        }
      >
        <img
          className={`imageCar`}
          style={{ width: "unset" }}
          alt=""
          src={`https://static-assets.tesla.com/configurator/compositor?&options=${
            data["carOptions"] ||
            "$APBS,$DV2W,$IBB0,$PPSW,$PRM30,$SC04,$MDL3,$W40B,$MT314,$CPF0"
          }&view=STUD_3QTR&model=${getCarModel(
            data["model"] || "Model S"
          )}&size=1441&bkba_opt=0`}
        />
      </div>
    </Col>
  );
}
