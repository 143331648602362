import ButtonComponent from "components/button/buttonComponent";
import LanguageSwicher from "components/LanguageSwicher";
import { RESPONSIVE, WEBSITE_URL } from "constant";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useMediaQuery } from "react-responsive";
import BackgroundLandingPage from "../../../assets/BackgroundLandingPage.jpg";
import BackgroundLandingPageMobile from "../../../assets/BackgroundLandingPageMobile.jpg";
import "./index.css";

let isPopUpBlocked = undefined;

const LandingPage = ({ children }) => {
  const [isShowLanding, setShowLanding] = useState(
    _.isNull(localStorage.getItem("leasingFormStep"))
  );
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  const { formatMessage } = useIntl();
  const [windowHeight, setWindowHeight] = useState(
    document.getElementById("root").clientHeight
  );
  const currentURL = window.location.href;

  localStorage.setItem("setted currentStepUrl", currentURL);

  // Check pop-up is blocked or not by opening pop-up
  if (isPopUpBlocked === undefined && isShowLanding) {
    let detectBlocker = window.open(
      "",
      "",
      "width=1,height=1,left=0,top=0,scrollbars=no"
    );
    if (detectBlocker) {
      isPopUpBlocked = false;
      detectBlocker.close();
    } else {
      isPopUpBlocked = true;
    }
  }

  // Set height when the height is changed and skip page when the page is rendered
  useEffect(() => {
    function handleResize() {
      const height = document.getElementById("root").clientHeight;
      setWindowHeight(height);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isShowLanding ? (
    <div
      style={{
        overflow: "auto",
        height: `${windowHeight}px`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "100%",
        backgroundAttachment: "fixed",
        backgroundImage: `url(${
          isMobile ? BackgroundLandingPageMobile : BackgroundLandingPage
        })`,
        display: "flex",
        flexDirection: "column",
      }}>
      <div
        className="navbar bg-light"
        style={
          isMobile ? { opacity: 0.85, padding: "10px" } : { opacity: 0.85 }
        }>
        <Row className="w-100 align-items-center p-0 m-0">
          <Col className="w-100 justify-content-start p-0">
            <div
              style={
                isMobile
                  ? { height: 32, width: 158 }
                  : { height: 42 * 1.5, width: 168 * 1.5 }
              }>
              <img
                className="logo-image"
                src={`${process.env.PUBLIC_URL}/logo.png`}
                alt="logo-header"
              />
            </div>
          </Col>
          <Col className="d-flex justify-content-end p-0">
            <LanguageSwicher />
          </Col>
        </Row>
      </div>
      <div
        style={{ color: "white", padding: 60, flex: "1 0 auto" }}
        className={isMobile ? "px-4" : ""}>
        <div style={{ margin: "20px 0", fontFamily: "Montserrat, Sans-serif" }}>
          <Row
            className={"font-weight-bold"}
            style={isMobile ? { fontSize: 21 } : { fontSize: 40 }}>
            {formatMessage({ id: "landing.text.welcome" })}
          </Row>
          <Row style={isMobile ? { fontSize: 12 } : { fontSize: 19 }}>
            {formatMessage({ id: "landing.text.welcomeDescription" })}
          </Row>
        </div>
        <div style={{ fontFamily: "Roboto" }}>
          <Row style={isMobile ? { fontSize: 13 } : { fontSize: 19 }}>
            {formatMessage({ id: "landing.text.leasingEasy" })}
          </Row>
          <Row style={isMobile ? { fontSize: 13 } : { fontSize: 19 }}>
            {formatMessage({ id: "landing.text.roughlyMinutes" })}
          </Row>
          <Row style={isMobile ? { fontSize: 13 } : { fontSize: 19 }}>
            {formatMessage({ id: "landing.text.simpleSafeSust" })}
          </Row>
          <Row
            style={
              isMobile
                ? { fontSize: 15, marginTop: "10px" }
                : { fontSize: 19, marginTop: "15px" }
            }>
            {formatMessage({
              id: "landing.text.landing.text.threeSimpleSteps",
            })}
          </Row>
          <Row className="my-4 ml-0">
            <div>
              <Row
                style={isMobile ? { fontSize: 13 } : { fontSize: 19 }}
                className="d-flex align-items-center">
                <span
                  className={`bg-green rounded-circle text-center ${
                    isMobile ? "mbadge-step" : "dbadge-step"
                  }`}>
                  1
                </span>
                {formatMessage({ id: "landing.text.stepOne" })}
              </Row>
              <Row
                style={isMobile ? { fontSize: 13 } : { fontSize: 19 }}
                className="d-flex align-items-center margin-step">
                <span
                  className={`bg-green rounded-circle text-center ${
                    isMobile ? "mbadge-step" : "dbadge-step"
                  }`}>
                  2
                </span>
                {formatMessage({ id: "landing.text.stepTwo" })}
              </Row>
              <Row
                style={isMobile ? { fontSize: 13 } : { fontSize: 19 }}
                className="d-flex align-items-center margin-step">
                <span
                  className={`bg-green rounded-circle text-center ${
                    isMobile ? "mbadge-step" : "dbadge-step"
                  }`}>
                  3
                </span>
                {formatMessage({ id: "landing.text.stepThree" })}
              </Row>
            </div>

            <Col>
              <ButtonComponent
                style={
                  isMobile
                    ? {
                        fontSize: "large",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "15px",
                        width: "310px",
                      }
                    : {
                        fontSize: "x-large",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "70px",
                        width: "450px",
                        float: "right",
                      }
                }
                className={`${
                  isMobile ? "layout-button-mobile" : "layout-button"
                } bg-green`}
                onClick={() => {
                  setShowLanding(false);
                }}
                label={formatMessage({ id: "landing.button.getStarted" })}
              />
            </Col>
          </Row>
          {isPopUpBlocked && (
            <Row
              style={
                isMobile
                  ? {
                      fontSize: 10,
                      justifyContent: "space-between",
                      marginLeft: "10px",
                      marginTop: "-15px",
                      width: "310px",
                    }
                  : {
                      fontSize: 12,
                      justifyContent: "flex-center",
                      float: "right",
                      marginRight: "20px",
                      marginTop: "-20px",
                      width: "400px",
                    }
              }>
              {formatMessage({ id: "landing.text.safari" })}
            </Row>
          )}
        </div>
      </div>
      <div className="footer w3-container">
        <Row className="w-100 align-items-center p-0 m-0">
          <div
            style={{ fontFamily: "Roboto", flexGrow: 0.5, marginLeft: "10px" }}>
            <Col style={isMobile ? { fontSize: 10 } : { fontSize: 13 }}>
              <a href={WEBSITE_URL}>
                {formatMessage({ id: "landing.text.visitWebsite" })}
              </a>
            </Col>
            <Col style={isMobile ? { fontSize: 10 } : { fontSize: 13 }}>
              <a href={"https://leaseteq.ch/haeufig-gestellte-fragen/"}>
                {formatMessage({ id: "landing.text.faq" })}
              </a>
            </Col>
          </div>
          <div style={{ fontFamily: "Roboto", flexGrow: 4 }}>
            <Col style={isMobile ? { fontSize: 10 } : { fontSize: 13 }}>
              <a href={"https://leaseteq.ch/impressum/"}>
                {formatMessage({ id: "landing.text.imprint" })}
              </a>
            </Col>
            <Col style={isMobile ? { fontSize: 10 } : { fontSize: 13 }}>
              <a href={"https://leaseteq.ch/datenschutz/"}>
                {formatMessage({ id: "landing.text.dataPrivacy" })}
              </a>
            </Col>
          </div>
          <div style={{ fontFamily: "Roboto", flexGrow: 1 }}>
            <Col style={isMobile ? { fontSize: 10 } : { fontSize: 13 }}>
              <a href={"tel:43192805960"}>
                <b>P&nbsp;</b>
                {formatMessage({ id: "landing.text.phone" })}
              </a>
            </Col>
            <Col style={isMobile ? { fontSize: 10 } : { fontSize: 13 }}>
              <a href={"mailto:support@leaseteq.com"}>
                <b>E&nbsp;&nbsp;</b>
                {formatMessage({ id: "landing.text.email" })}
              </a>
            </Col>
          </div>
        </Row>
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};

export default React.memo(LandingPage);
